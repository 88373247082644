<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M939.776 249.088h-158.72l-1.28-1.664-2.176 1.664-0.128 0.128h-499.2v-0.128c-20.48 0-37.12 16.64-37.12 37.12v363.904l0.128-0.128v73.344c0 20.608 16.512 37.12 36.992 37.12h494.08c33.536 0 65.664 13.824 88.832 38.144l83.712 88.064c11.52 12.16 31.872 3.968 31.872-12.672V286.208h0.128c0-20.48-16.64-37.12-37.12-37.12z"
      fill="#BAE7FF"
      p-id="10663"
    ></path>
    <path
      d="M744.832 130.816H83.328c-20.48 0-37.12 16.64-37.12 37.12v587.776c0 16.768 20.48 24.832 31.872 12.672l83.712-88.064c23.168-24.32 55.296-38.144 88.832-38.144h494.08c20.48 0 37.12-16.64 37.12-37.12V167.808c0-20.352-16.512-36.992-36.992-36.992z m0 0"
      fill="#319EEC"
      p-id="10664"
    ></path>
    <path
      d="M656.256 346.112H198.784c-14.848 0-27.008-12.032-27.008-27.008 0-14.848 12.032-27.008 27.008-27.008h457.472c14.848 0 27.008 12.032 27.008 27.008 0 14.976-12.032 27.008-27.008 27.008zM440.96 480.64H198.784c-14.848 0-27.008-12.032-27.008-27.008 0-14.848 12.032-27.008 27.008-27.008h242.176c14.848 0 27.008 12.032 27.008 27.008-0.128 14.976-12.16 27.008-27.008 27.008z m0 0"
      fill="#FFFFFF"
      p-id="10665"
    ></path>
  </svg>
</template>

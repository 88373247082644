<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M950.29 898.52L823.56 771.79A430.69 430.69 0 1 0 97 327.05a430.65 430.65 0 0 0 675.76 495.8l126.62 126.59a36 36 0 1 0 50.91-50.92z m-456.44-45.13c-197.78 0-358.68-160.91-358.68-358.69S296.07 136 493.85 136s358.68 160.93 358.68 358.7-160.9 358.69-358.68 358.69z"
      p-id="49328"
    ></path>
    <path
      d="M268.63 517.91m36 0l0 0q36 0 36 36l0 126.03q0 36-36 36l0 0q-36 0-36-36l0-126.03q0-36 36-36Z"
      p-id="49329"
    ></path>
    <path
      d="M461.06 268m36 0l0 0q36 0 36 36l0 375.93q0 36-36 36l0 0q-36 0-36-36l0-375.93q0-36 36-36Z"
      p-id="49330"
    ></path>
    <path
      d="M653.49 368.19m36 0l0 0q36 0 36 36l0 275.74q0 36-36 36l0 0q-36 0-36-36l0-275.74q0-36 36-36Z"
      p-id="49331"
    ></path>
  </svg>

</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M512 64.887129a447.234535 447.234535 0 0 1 174.00903 859.085307A447.228451 447.228451 0 0 1 337.990971 99.997149 444.172673 444.172673 0 0 1 512 64.887129m0-64.887129c-282.765941 0-512 229.223921-512 512s229.223921 512 512 512 512-229.223921 512-512-229.234059-512-512-512z"
      p-id="100870"
    ></path>
    <path
      d="M272.876737 320.32545l45.882297-45.882298 430.61836 430.617347-45.882297 45.883311z"
      p-id="100871"
    ></path>
    <path
      d="M273.583398 704.854685l430.617347-430.617346 45.883311 45.881283-430.618361 430.61836z"
      p-id="100872"
    ></path>
  </svg>
</template>

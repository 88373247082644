


<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M426.666667 170.666667h469.333333a42.666667 42.666667 0 0 1 42.666667 42.666666v85.333334a42.666667 42.666667 0 0 1-42.666667 42.666666H426.666667a42.666667 42.666667 0 0 1-42.666667-42.666666V213.333333a42.666667 42.666667 0 0 1 42.666667-42.666666z m0 256h469.333333a42.666667 42.666667 0 0 1 42.666667 42.666666v85.333334a42.666667 42.666667 0 0 1-42.666667 42.666666H426.666667a42.666667 42.666667 0 0 1-42.666667-42.666666v-85.333334a42.666667 42.666667 0 0 1 42.666667-42.666666z m0 256h469.333333a42.666667 42.666667 0 0 1 42.666667 42.666666v85.333334a42.666667 42.666667 0 0 1-42.666667 42.666666H426.666667a42.666667 42.666667 0 0 1-42.666667-42.666666v-85.333334a42.666667 42.666667 0 0 1 42.666667-42.666666z"
      p-id="24389"
    ></path>
    <path
      d="M85.333333 170.666667m42.666667 0l128 0q42.666667 0 42.666667 42.666666l0 597.333334q0 42.666667-42.666667 42.666666l-128 0q-42.666667 0-42.666667-42.666666l0-597.333334q0-42.666667 42.666667-42.666666Z"
      opacity=".3"
      p-id="24390"
    ></path>
  </svg>
</template>




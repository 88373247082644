<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M847.872 240.128v688c0 26.56-21.408 48-48 48h-576c-26.56 0-48-21.44-48-48v-832c0-26.592 21.44-48 48-48h432z"
      fill="#E9EDED"
      p-id="2331"
    ></path>
    <path
      d="M160 768.128v160c0 35.456 28.544 64 64 64h576c35.456 0 64-28.544 64-64v-160H160z"

      p-id="2332"
    ></path>
    <path
      d="M847.872 240.128h-144c-26.56 0-48-21.44-48-48v-144"

      p-id="2333"
    ></path>
    <path
      d="M432.256 320.128c-35.2 0-64 28.8-64 64v32c0 18.016-14.016 32-32 32a16 16 0 0 0-15.936 12.992 16 16 0 0 0 0 0.064 16 16 0 0 0 4.736 14.56 16 16 0 0 0 2.496 1.92 16 16 0 0 0 4.448 1.92 16 16 0 0 0 3.136 0.48 16 16 0 0 0 1.12 0.064c17.984 0 32 13.984 32 32v32c0 35.2 28.8 64 64 64a16 16 0 1 0 0-32c-18.016 0-32-13.984-32-32v-32c0-19.136-8.736-36.256-22.208-48a63.68 63.68 0 0 0 22.208-48v-32c0-18.016 13.984-32 32-32a16 16 0 1 0 0-32z m125.856 0a16 16 0 0 0 1.632 32c18.016 0 32 13.984 32 32v32c0 19.168 8.736 36.224 22.208 48-13.44 11.744-22.208 28.864-22.208 48v32c0 18.016-13.984 32-32 32a16 16 0 1 0 0 32c35.2 0 64-28.8 64-64v-32c0-18.016 14.016-32 32-32a16 16 0 0 0 10.368-3.616 16 16 0 0 0 1.216-1.152 16 16 0 0 0-11.584-27.232c-17.984 0-32-13.984-32-32v-32c0-35.2-28.8-64-64-64a16 16 0 0 0-1.6 0zM496 384a16 16 0 0 0-16 16 16 16 0 0 0 16 16 16 16 0 0 0 16-16 16 16 0 0 0-16-16z m-0.256 63.808A16 16 0 0 0 480 464v96a16 16 0 1 0 32 0v-96a16 16 0 0 0-16.256-16.192z"
      fill="#F17F53"
      p-id="2334"
    ></path>
    <path
      d="M720.576 799.616a16 16 0 0 0-1.632 0.064 16 16 0 0 0-15.008 17.312v126.816a16 16 0 1 0 32 0v-80l66.624 88.96a16 16 0 0 0 0.064 0.096l0.448 0.576a16 16 0 0 0 28.864-9.6v-127.68a16 16 0 0 0-16.256-16.224 16 16 0 0 0-15.744 16.256v79.68l-67.136-89.6a16 16 0 0 0-12.224-6.656zM303.744 800a16 16 0 0 0-15.744 16.256V912c0 9.152-6.848 16-16 16a15.616 15.616 0 0 1-16-16 16 16 0 0 0-16.256-16.192A16 16 0 0 0 224 912c0 26.304 21.696 48 48 48 24.768 0 45.152-19.296 47.488-43.52a16 16 0 0 0 0.512-4.224v-96A16 16 0 0 0 303.744 800z m288.256 0a80.256 80.256 0 0 0-80 80c0 44 36 80 80 80s80-36 80-80-36-80-80-80z m-193.888 0.128a16 16 0 0 0-1.984 0.384c-24.64 1.92-44.384 22.528-44.384 47.616 0 25.28 20.096 46.08 44.992 47.68a16 16 0 0 0 3.008 0.32h32c9.152 0 16 6.848 16 16 0 9.152-6.848 16-16 16h-64a16 16 0 1 0 0 32h64a16 16 0 0 0 3.328-0.384 48.096 48.096 0 0 0 44.672-47.616c0-25.152-19.84-45.888-44.544-47.68a16 16 0 0 0-3.456-0.32h-30.88a16 16 0 0 0-1.12 0 15.616 15.616 0 0 1-16-16c0-9.152 6.88-16 16-16h64a16 16 0 1 0 0-32h-62.88a16 16 0 0 0-1.12 0 16 16 0 0 0-1.6 0zM592 832c26.688 0 48 21.312 48 48s-21.312 48-48 48-48-21.312-48-48 21.312-48 48-48z"
      fill="#E9EDED"
      p-id="2335"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M768.7 719H255.3c-43.2 0-78.3-35.1-78.3-78.3V267.3c0-43.2 35.1-78.3 78.3-78.3h513.5c43.2 0 78.3 35.1 78.3 78.3v373.5c-0.1 43.1-35.2 78.2-78.4 78.2zM255.3 239c-15.6 0-28.3 12.7-28.3 28.3v373.5c0 15.6 12.7 28.3 28.3 28.3h513.5c15.6 0 28.3-12.7 28.3-28.3V267.3c0-15.6-12.7-28.3-28.3-28.3H255.3zM847 785H177c-13.8 0-25 11.2-25 25s11.2 25 25 25h670c13.8 0 25-11.2 25-25s-11.2-25-25-25z"
      p-id="3150"
    ></path>
    <path
      d="M701.8 564.5H532.2c-13.8 0-25 11.2-25 25s11.2 25 25 25h169.6c13.8 0 25-11.2 25-25s-11.2-25-25-25zM455.2 300.4c-6.4 0-12.8 2.4-17.7 7.3L303.2 442.1c-9.8 9.8-9.8 25.6 0 35.4l134.4 134.4c4.9 4.9 11.3 7.3 17.7 7.3s12.8-2.4 17.7-7.3c9.8-9.8 9.8-25.6 0-35.4L356.2 459.8l116.7-116.7c9.8-9.8 9.8-25.6 0-35.4-4.9-4.8-11.3-7.3-17.7-7.3z"
      p-id="3151"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1177.713778 344.945778 740.408889 673.308444l0-230.968889c-418.360889 0-436.878222 253.667556-437.447111 288.199111 0-16.782222 0-82.460444 0-93.44 0-145.009778 84.849778-389.518222 437.447111-389.518222L740.408889 28.444444 1177.713778 344.945778zM355.697778 534.186667c0 0 87.808-140.544 396.458667-140.544 22.584889 0 46.392889 0 46.392889 0l0 170.410667 289.507556-219.107556L798.549333 137.671111l0 164.096c0 0-23.864889 0-46.392889 0C421.063111 301.767111 355.697778 534.186667 355.697778 534.186667zM302.961778 730.538667c0 2.474667 0 3.953778 0 3.953778S302.904889 733.041778 302.961778 730.538667zM186.311111 175.559111l0 706.019556c0 32.540444 26.112 58.823111 58.311111 58.823111l728.974222 0c32.199111 0 58.311111-26.311111 58.311111-58.823111l0-176.526222 58.339556 0 0 176.526222c0 64.967111-52.224 117.674667-116.650667 117.674667L244.650667 999.253333c-64.426667 0-116.650667-52.707556-116.650667-117.674667L128 175.559111c0-64.995556 52.224-117.674667 116.650667-117.674667l262.428444 0 0 58.823111L244.650667 116.707556C212.423111 116.707556 186.311111 143.047111 186.311111 175.559111z"
      p-id="3422"
    ></path>
  </svg>
</template>



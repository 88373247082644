/*
 * @Descripttion: 工具集
 * @version: 1.2
 * @LastEditors: sakuya
 * @LastEditTime: 2022年5月24日00:28:56
 */

import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";
import sysConfig from "@/config";
import { nanoid } from "nanoid";
import dayjs from "dayjs";

import Clipboard from "clipboard";

const tool = { dayjs: dayjs };

tool.app = {
	setId(id) {
		tool.data.set("appId", id);
	},
	setName(name) {
		tool.data.set("appName", name);
	},
	id() {
		return tool.data.get("appId") || "default";
	},
	name() {
		return tool.data.get("appName") || "";
	},
};
/* localStorage */
tool.data = {
	set(key, data, datetime = 0) {
		//加密
		if (sysConfig.LS_ENCRYPTION == "AES") {
			data = tool.crypto.AES.encrypt(
				JSON.stringify(data),
				sysConfig.LS_ENCRYPTION_key
			);
		}
		let cacheValue = {
			content: data,
			datetime:
				parseInt(datetime) === 0
					? 0
					: new Date().getTime() + parseInt(datetime) * 1000,
		};
		return localStorage.setItem(key, JSON.stringify(cacheValue));
	},
	get(key) {
		try {
			const value = JSON.parse(localStorage.getItem(key));
			if (value) {
				let nowTime = new Date().getTime();
				if (nowTime > value.datetime && value.datetime != 0) {
					localStorage.removeItem(key);
					return null;
				}
				//解密
				if (sysConfig.LS_ENCRYPTION == "AES") {
					value.content = JSON.parse(
						tool.crypto.AES.decrypt(
							value.content,
							sysConfig.LS_ENCRYPTION_key
						)
					);
				}
				return value.content;
			}
			return null;
		} catch (err) {
			return null;
		}
	},
	remove(key) {
		return localStorage.removeItem(key);
	},
	clear() {
		return localStorage.clear();
	},
};

/*sessionStorage*/
tool.session = {
	set(key, settings) {
		var _set = JSON.stringify(settings);
		return sessionStorage.setItem(key, _set);
	},
	get(key) {
		var data = sessionStorage.getItem(key);
		try {
			data = JSON.parse(data);
		} catch (err) {
			return null;
		}
		return data;
	},
	remove(key) {
		return sessionStorage.removeItem(key);
	},
	clear() {
		return sessionStorage.clear();
	},
};

/*cookie*/
tool.cookie = {
	set(name, value, config = {}) {
		var cfg = {
			expires: null,
			path: null,
			domain: null,
			secure: false,
			httpOnly: false,
			...config,
		};
		var cookieStr = `${name}=${escape(value)}`;
		if (cfg.expires) {
			var exp = new Date();
			exp.setTime(exp.getTime() + parseInt(cfg.expires) * 1000);
			cookieStr += `;expires=${exp.toGMTString()}`;
		}
		if (cfg.path) {
			cookieStr += `;path=${cfg.path}`;
		}
		if (cfg.domain) {
			cookieStr += `;domain=${cfg.domain}`;
		}
		document.cookie = cookieStr;
	},
	get(name) {
		var arr = document.cookie.match(
			new RegExp("(^| )" + name + "=([^;]*)(;|$)")
		);
		if (arr != null) {
			return unescape(arr[2]);
		} else {
			return null;
		}
	},
	remove(name) {
		var exp = new Date();
		exp.setTime(exp.getTime() - 1);
		document.cookie = `${name}=;expires=${exp.toGMTString()}`;
	},
};

tool.string = {
	isEmptyStr(str) {
		return (
			str === undefined ||
			(!str && str !== 0 && str !== "0") ||
			!/[^\s]/.test(str)
		);
	},
	notEmpty(script) {
		var reg = /(\S)/;
		return reg.test(script || "");
	},
};

tool.object = {
	isNull(value) {
		return value === null || value === undefined;
	},

	isNotNull(value) {
		return value !== null && value !== undefined;
	},
	/* 浅拷贝对象属性，obj2覆盖obj1 */
	overwrite(obj1, obj2) {
		Object.keys(obj2).forEach((prop) => {
			obj1[prop] = obj2[prop];
		});
	},
	deepClone(origin) {
		if (origin === undefined) {
			return undefined;
		}
		return JSON.parse(JSON.stringify(origin));
	},
	optionExists(optionsObj, optionName) {
		if (!optionsObj) {
			return false;
		}
		return Object.keys(optionsObj).indexOf(optionName) > -1;
	},
};
/* 常用加解密 */
tool.crypto = {
	//MD5加密
	MD5(data) {
		return CryptoJS.MD5(data).toString();
	},
	//BASE64加解密
	BASE64: {
		encrypt(data) {
			return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(data));
		},
		decrypt(cipher) {
			return CryptoJS.enc.Base64.parse(cipher).toString(
				CryptoJS.enc.Utf8
			);
		},
	},
	//AES加解密
	AES: {
		encrypt(data, secretKey, config = {}) {
			if (secretKey.length % 8 != 0) {
				console.warn(
					"[AES error]: 秘钥长度需为8的倍数，否则解密将会失败。"
				);
			}
			const result = CryptoJS.AES.encrypt(
				data,
				CryptoJS.enc.Utf8.parse(secretKey),
				{
					iv: CryptoJS.enc.Utf8.parse(config.iv || ""),
					mode: CryptoJS.mode[config.mode || "ECB"],
					padding: CryptoJS.pad[config.padding || "Pkcs7"],
				}
			);
			return result.toString();
		},
		decrypt(cipher, secretKey, config = {}) {
			const result = CryptoJS.AES.decrypt(
				cipher,
				CryptoJS.enc.Utf8.parse(secretKey),
				{
					iv: CryptoJS.enc.Utf8.parse(config.iv || ""),
					mode: CryptoJS.mode[config.mode || "ECB"],
					padding: CryptoJS.pad[config.padding || "Pkcs7"],
				}
			);
			return CryptoJS.enc.Utf8.stringify(result);
		},
	},
	//RAS加解密
	RAS: {
		encrypt(data, secretKey) {
			let encryptor = new JSEncrypt(); // 新建JSEncrypt对象
			encryptor.setPublicKey(secretKey); // 设置公钥
			return encryptor.encrypt(data);
		},
		decrypt(data, secretKey) {
			let encryptor = new JSEncrypt(); // 新建JSEncrypt对象
			encryptor.setPrivateKey(secretKey); // 设置私钥
			return encryptor.decrypt(data);
		},
	},
};
/* id生成对象 */
tool.id = {
	uuid() {
		return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
			/[xy]/g,
			function (c) {
				var r = (Math.random() * 16) | 0,
					v = c == "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);
	},
	shortUuid() {
		return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(
			/[xy]/g,
			function (c) {
				var r = (Math.random() * 16) | 0,
					v = c == "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			}
		);
	},
	nid(size) {
		return nanoid(size);
	},
	randomId() {
		return Math.floor(
			Math.random() * 100000 +
				Math.random() * 20000 +
				Math.random() * 5000
		);
	},
};
//数组工具
tool.array = {
	union: (a, b) => {
		return new Set([new Set(a), new Set(b)]);
	},
	intersect: (a, b) => {
		let set = new Set(b);
		return [...new Set(a.filter((x) => set.has(x)))];
	},
	difference: (a, b) => {
		let set = new Set(b);
		return [...new Set(a.filter((x) => !set.has(x)))];
	},

	duplicate: (arr, uniId) => {
		const res = new Map();
		return arr.filter(
			(item) => !res.has(item[uniId]) && res.set(item[uniId], 1)
		);
	},

	swap: (arr, index1, index2) => {
		arr[index1] = arr.splice(index2, 1, arr[index1])[0];
		return arr;
	},

	insert: (arr, index, item) => {
		return arr.slice(0, index).concat(item, arr.slice(index));
	},
};
/* 数据格式转换对象 */
tool.convert = {
	/* 树形数据转换
	 * @param {*} data
	 * @param {*} id
	 * @param {*} pid
	 * @param {*} children
	 */
	toTreeNode(data, id = "id", pid = "parentId", children = "children") {
		var res = [];
		var temp = {};
		for (var i = 0; i < data.length; i++) {
			temp[data[i][id]] = data[i];
		}
		for (var k = 0; k < data.length; k++) {
			if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
				if (!temp[data[k][pid]][children]) {
					temp[data[k][pid]][children] = [];
				}
				if (!temp[data[k][pid]]["_level"]) {
					temp[data[k][pid]]["_level"] = 1;
				}
				data[k]["_level"] = temp[data[k][pid]]._level + 1;
				temp[data[k][pid]][children].push(data[k]);
			} else {
				res.push(data[k]);
			}
		}
		return res;
	},

	toCamp(str) {
		const reg = /_\w/g;

		return str.toLowerCase().replace(reg, (s) => s[1].toUpperCase());
	},
};
/* Fullscreen */
tool.screen = function (element) {
	var isFull =
		document.webkitIsFullScreen ||
		document.mozFullScreen ||
		document.msFullscreenElement ||
		document.fullscreenElement;
	if (isFull) {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		}
	} else {
		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.msRequestFullscreen) {
			element.msRequestFullscreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullscreen) {
			element.webkitRequestFullscreen();
		}
	}
};
/* 日期格式化 */
tool.dateFormat = function (date, fmt = "yyyy-MM-dd hh:mm:ss") {
	date = new Date(date);
	var o = {
		"M+": date.getMonth() + 1, //月份
		"d+": date.getDate(), //日
		"h+": date.getHours(), //小时
		"m+": date.getMinutes(), //分
		"s+": date.getSeconds(), //秒
		"q+": Math.floor((date.getMonth() + 3) / 3), //季度
		S: date.getMilliseconds(), //毫秒
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(
			RegExp.$1,
			(date.getFullYear() + "").substr(4 - RegExp.$1.length)
		);
	}
	for (var k in o) {
		if (new RegExp("(" + k + ")").test(fmt)) {
			fmt = fmt.replace(
				RegExp.$1,
				RegExp.$1.length == 1
					? o[k]
					: ("00" + o[k]).substr(("" + o[k]).length)
			);
		}
	}
	return fmt;
};
/* 千分符 */
tool.groupSeparator = function (num) {
	num = num + "";
	if (!num.includes(".")) {
		num += ".";
	}
	return num
		.replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
			return $1 + ",";
		})
		.replace(/\.$/, "");
};
/* 复制到剪贴板*/
tool.copyToClipboard = function (
	content,
	clickEvent,
	$message,
	successMsg,
	errorMsg
) {
	const clipboard = new Clipboard(clickEvent.target, {
		text: () => content,
	});

	clipboard.on("success", () => {
		$message.success(successMsg);
		clipboard.destroy();
	});

	clipboard.on("error", () => {
		$message.error(errorMsg);
		clipboard.destroy();
	});

	clipboard.onClick(clickEvent);
};
/**
 * 生成并调用函数
 * @param {*} s  函数脚本
 * @param {*} p  函数参数
 * @param _this  this对象
 * @param target 函数或脚本名称
 * @returns
 */
tool.funCall = function (s, p, _this, target = "") {
	if (this.string.isEmptyStr(s)) return null;

	let keys = [];
	let values = [];
	p = p || {};
	for (let key in p) {
		keys.push(key);
		values.push(p[key]);
	}

	let ret = null;
	try {
		ret = new Function(...keys, s).call(_this || this, ...values);
	} catch (e) {
		console.info(
			`%c【异常】 动态函数：funCall:\n\n%c${e}\n\n\n%c【异常脚本】${target}\n\n%c${s}\n\n`,
			"color:#ff0000;font-size: 12px;font-weight:bolder",
			"color:#888;font-size: 9px",
			"color:#10D070;font-size: 12px;",
			"color:#10D070;font-size: 9px",
			"color:#888;font-size: 9px"
		);

		ret = null;
	}

	return ret;
};

tool.vf = function (vf, v, f) {
	return { vf: vf || "v", v: v, f: f };
};

export default tool;

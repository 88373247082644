<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M524.552258 848.929032c184.32 0 333.625806-149.966452 333.625807-333.625806s-149.966452-333.625806-333.625807-333.625807c-184.32 0-333.625806 149.966452-333.625806 333.625807s149.305806 333.625806 333.625806 333.625806m0 48.227097c-210.745806 0-381.852903-171.107097-381.852903-381.852903s171.107097-381.852903 381.852903-381.852903 381.192258 171.107097 381.192258 381.852903-170.446452 381.852903-381.192258 381.852903z"
      p-id="47573"
    ></path>
    <path
      d="M503.411613 599.865806c-44.923871 25.765161-71.349677 56.154839-71.349678 56.154839-13.212903 15.194839-34.353548 20.48-52.851612 13.873549s-31.050323-24.443871-31.050323-44.923871V404.975484c0-19.819355 12.552258-37.656774 31.050323-44.923871 18.498065-6.606452 39.63871-1.32129 52.851612 13.212903 0 0 27.747097 31.050323 73.331613 57.476129 11.230968 6.606452 15.194839 21.140645 8.588387 32.371613-6.606452 11.230968-21.140645 15.194839-32.371612 8.588387-52.190968-29.068387-83.901935-64.082581-85.883871-66.725161v219.994839c1.981935-2.642581 32.371613-36.996129 83.901935-66.725162 11.230968-6.606452 25.765161-2.642581 32.371613 8.588387 6.606452 11.891613 2.642581 26.425806-8.588387 33.032258z"
      p-id="47574"
    ></path>
    <path
      d="M522.570323 625.630968L713.496774 515.303226 522.570323 404.975484v220.655484m0 47.566451c-7.927742 0-16.516129-1.981935-23.783226-6.606451-14.534194-8.588387-23.783226-24.443871-23.783226-40.96V404.975484c0-17.176774 9.249032-33.032258 23.783226-40.96 7.267097-3.963871 15.855484-6.606452 23.783226-6.606452 7.927742 0 16.516129 1.981935 23.783225 6.606452l190.926452 110.327742c14.534194 8.588387 23.783226 24.443871 23.783226 41.620645 0 17.176774-9.249032 33.032258-23.783226 41.620645l-190.926452 110.327742c-7.267097 3.303226-15.194839 5.285161-23.783225 5.285161z"
      p-id="47575"
    ></path>
  </svg>
</template>




<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M676.48 72.96l209.92 211.2 9.6 23.04v620.8l-32 32h-704l-32-32v-832l32-32h494.08l22.4 8.96zM640 320h192l-192-192v192zM192 128v768h640V384H608L576 352V128H192z m512 320H320v64h384V448zM320 576h384v64H320V576z m384 128H320v64h384v-64z"
      p-id="65361"
    ></path>
  </svg>
</template>




<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">

    <path
      d="M554.666667 853.333333h298.666666V170.666667h-298.666666v682.666666z m-85.333334 0V170.666667H170.666667v682.666666h298.666666zM938.666667 170.666667v682.666666a85.333333 85.333333 0 0 1-85.333334 85.333334H170.666667a85.333333 85.333333 0 0 1-85.333334-85.333334V170.666667a85.333333 85.333333 0 0 1 85.333334-85.333334h682.666666a85.333333 85.333333 0 0 1 85.333334 85.333334z"
      p-id="11202"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M467.1 468.1H235.8c-61.8 0-119.9-24.1-163.5-67.7S4.5 298.6 4.5 236.8 28.6 116.9 72.2 73.3 174.1 5.5 235.8 5.5c61.8 0 119.9 24.1 163.5 67.7S467 175 467 236.7v231.4zM235.8 34.5c-111.6 0-202.4 90.8-202.4 202.4 0 111.6 90.8 202.4 202.4 202.4h202.4V236.8c0-111.6-90.8-202.3-202.4-202.3zM568.3 453.7h216.8c119.8 0 216.8-97.1 216.8-216.8 0.1-119.8-97-216.9-216.7-216.9-119.8 0-216.8 97.1-216.8 216.8v216.9z"
      fill=""
      p-id="8499"
    ></path>
    <path
      d="M785.2 468.1H553.9V236.8c0-61.8 24.1-119.9 67.7-163.5C665.3 29.6 723.4 5.5 785.2 5.5s119.9 24.1 163.5 67.7c43.7 43.7 67.7 101.8 67.7 163.5s-24.1 119.9-67.7 163.5c-43.7 43.9-101.8 67.9-163.5 67.9z m-202.4-28.9h202.4c111.6 0 202.4-90.8 202.4-202.4 0-111.6-90.8-202.4-202.4-202.4-111.6 0-202.4 90.8-202.4 202.4v202.4zM452.7 569.3H235.8C116.1 569.3 19 666.4 19 786.2 19 906 116.1 1003 235.8 1003c119.8 0 216.8-97.1 216.8-216.8V569.3z"
      fill=""
      p-id="8500"
    ></path>
    <path
      d="M235.8 1017.5c-61.8 0-119.9-24.1-163.5-67.7C28.6 906 4.5 847.9 4.5 786.2c0-61.8 24.1-119.9 67.7-163.5C115.9 579 174 555 235.7 555H467v231.3c0 61.8-24.1 119.9-67.7 163.5-43.6 43.6-101.7 67.7-163.5 67.7z m0-433.7c-111.6 0-202.4 90.8-202.4 202.4 0 111.6 90.8 202.4 202.4 202.4 111.6 0 202.4-90.8 202.4-202.4V583.8H235.8zM785.2 1017.5c-61.8 0-119.9-24.1-163.5-67.7C578 906.1 554 848 554 786.3V554.9h231.3c61.8 0 119.9 24.1 163.5 67.7 43.7 43.7 67.7 101.8 67.7 163.5 0 61.8-24.1 119.9-67.7 163.5-43.8 43.8-101.9 67.9-163.6 67.9zM582.8 583.8v202.4c0 111.6 90.8 202.4 202.4 202.4 111.6 0 202.4-90.8 202.4-202.4 0-111.6-90.8-202.4-202.4-202.4H582.8z"
      fill=""
      p-id="8501"
    ></path>
    <path
      d="M929.7 251.3h-28.9c0-71.7-58.4-130.1-130.1-130.1V92.3c87.7 0 159 71.3 159 159z"
      fill="#FFFFFF"
      p-id="8502"
    ></path>
    <path d="M914.5 266.1c-0.7 7.2-2 14.3-3.7 21.1" fill="" p-id="8503"></path>
    <path
      d="M924.8 290.8l-28-7.2c1.6-6.2 2.7-12.6 3.4-19l28.8 2.9c-0.9 7.9-2.3 15.7-4.2 23.3z"
      fill="#FFFFFF"
      p-id="8504"
    ></path>
  </svg>
</template>


const defaultSettings = {
    processId: `Process_${new Date().getTime()}`,
    processName: `业务流程`,
    processEngine: "flowable",
    paletteMode: "enhancement",
    penalMode: "custom",
    contextPadMode: "enhancement",
    rendererMode: "rewrite",
    bg: "grid-image",
    toolbar: true,
    useMinimap: true,
    useLint: true,
    useMock: true,
    contextmenu: true,
    customContextmenu: true,
    otherModule: true,
    templateChooser: true,
    customTheme: {}
};

/**
 * 返回数据原始类型
 * @param value
 * @return { string } type
 */
function getRawType(value) {
    return Object.prototype.toString.call(value).slice(8, -1).toLowerCase();
}

// 取消响应式
function unObserver(val) {
    if (getRawType(val) === "object" || getRawType(val) === "array") {
        val.__v_skip = true;
        return val;
    }
    return val;
}

export default {
    state: {
        editor: { ...defaultSettings },
        bpmn: {}
    },
    getters: {
        //  editor
        getEditor: (state) => state.editor,
        getProcessDef: (state) => ({
            processName: state.editor.processName,
            processId: state.editor.processId
        }),
        getProcessEngine: (state) => state.editor.processEngine,
        getEditorConfig: (state) => {
            return Object.keys(state.editor).reduce((config, key) => {
                if (!["processName", "processId", "processEngine"].includes(key)) {
                    config[key] = state.editor[key];
                }
                return config;
            }, {});
        },

        // modeler
        getModeler: (state) => state.bpmn._modeler,
        getModeling: (state) => (state.bpmn._modeler ? state.bpmn._modeler.get("modeling") : undefined),
        getActive: (state) => state.bpmn.activeElement
    },
    mutations: {
        // editor
        setConfiguration(state, conf) {
            state.editor = { ...state.editor, ...conf };
        },

        clearBpmnState(state) {
            state.bpmn = {};
        },
        /**
         * @param state
         * @param modeler { object }
         */
        setModeler(state, modeler) {
            state.bpmn._modeler = unObserver(modeler);
        },
        /**
         * @param state
         * @param key { string }
         * @param module { object }
         */
        // setModules(state, { key, module }) {
        //   // state.bpmn[`_${key}`] = Object.freeze(module);
        // },
        /**
         * @param state
         * @param id { string }
         * @param element { object }
         */
        setElement(state, { element, id }) {
            state.bpmn.activeElement = { element: unObserver(element), id };
        }
    }
}

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M512 99.56l250.03 144.36v288.7L512 676.97 261.97 532.62v-288.7z"
      fill="#386BF3"
      p-id="11167"
    ></path>
    <path
      d="M347.03 347.03l250.02 144.35v288.7L347.03 924.44 97 780.08v-288.7z"
      fill="#386BF3"
      p-id="11168"
    ></path>
    <path
      d="M597.05 627.87L512 676.97 261.97 532.62V396.13l85.06-49.1 250.02 144.35z"
      fill="#C3D2FB"
      p-id="11169"
    ></path>
    <path
      d="M676.97 347.03L927 491.38v288.7L676.97 924.44 426.95 780.08v-288.7z"
      fill="#386BF3"
      p-id="11170"
    ></path>
    <path
      d="M512 442.27l85.05 49.11v288.7L512 829.19l-85.05-49.11v-288.7z"
      fill="#C3D2FB"
      p-id="11171"
    ></path>
    <path
      d="M762.03 396.13v136.49L512 676.97l-85.05-49.1V491.38l250.02-144.35z"
      fill="#C3D2FB"
      p-id="11172"
    ></path>
    <path
      d="M597.05 627.87L512 676.97l-85.05-49.1V491.38L512 442.27l85.05 49.11z"
      fill="#3D4265"
      p-id="11173"
    ></path>
  </svg>
</template>

import http from "@/utils/request";
import { stringify } from 'querystring';
export default {
	getCurrentStatus: async () => http.get('/session/current_status'),
	getLoginEncryptSecret: async () => http.get('/public_secret?type=login'),
	accountLogin: async (params) => http.postForm('/login', stringify(params),
	{headers: {'content-type': 'application/x-www-form-urlencoded', 'Accept': 'application/json'}}),
	getCurrentUser: async () => http.get('/session/current_user'),
	getUserApp: async () => http.get('/app/list'),
	// logout: async (type) => http.get(`${module}/${type}/logout`),
};



<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M433.388308 157.538462a39.384615 39.384615 0 0 1 39.108923 34.776615l0.275692 4.608v118.153846a39.384615 39.384615 0 0 0 34.737231 39.108923l4.608 0.275692H787.692308a78.769231 78.769231 0 0 1 78.76923 78.769231v354.461539a78.769231 78.769231 0 0 1-78.76923 78.76923H236.307692a78.769231 78.769231 0 0 1-78.76923-78.76923V236.307692a78.769231 78.769231 0 0 1 78.76923-78.76923h197.080616z m0 31.507692H236.307692a47.261538 47.261538 0 0 0-47.02523 42.417231L189.046154 236.307692v551.384616a47.261538 47.261538 0 0 0 42.417231 47.02523L236.307692 834.953846h551.384616a47.261538 47.261538 0 0 0 47.02523-42.417231L834.953846 787.692308v-354.461539a47.261538 47.261538 0 0 0-42.417231-47.025231L787.692308 385.969231l-277.346462-0.039385-6.459077-0.393846A70.892308 70.892308 0 0 1 441.501538 320.984615L441.225846 315.076923V195.938462a7.876923 7.876923 0 0 0-7.837538-6.892308zM827.076923 157.538462a39.384615 39.384615 0 0 1 39.384615 39.384615v78.769231a39.384615 39.384615 0 0 1-39.384615 39.384615h-275.692308a39.384615 39.384615 0 0 1-39.384615-39.384615V196.923077a39.384615 39.384615 0 0 1 39.384615-39.384615h275.692308z m0 31.507692h-275.692308a7.876923 7.876923 0 0 0-7.60123 5.789538L543.507692 196.923077v78.769231a7.876923 7.876923 0 0 0 5.789539 7.60123L551.384615 283.569231h275.692308a7.876923 7.876923 0 0 0 7.601231-5.789539L834.953846 275.692308V196.923077a7.876923 7.876923 0 0 0-5.789538-7.601231L827.076923 189.046154z"

      p-id="7710"
    ></path>
  </svg>
</template>

/**
 * @description 自动import导入所有 api 模块,子目录为多层对象
 */

const files = require.context("./model", true, /\.js$/);
const modules = {};
files.keys().forEach((key) => {
	let keys = key.replace(/(\.\/|\.js)/g, "").split("/");
	let m = modules;
	for (let i = 0; i < keys.length; i++) {
		if (i == keys.length - 1) m[keys[i]] = files(key).default;
		else  if(!m[keys[i]]) m[keys[i]]={};
		m = m[keys[i]]
	}
});

export default modules;

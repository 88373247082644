<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M507 545.2c-8.2 0-16.6-2.1-24-6.3L112.6 350.8c-16-9.1-25.1-25-24.8-42.8 0.3-17.8 9.8-33.4 25.5-41.8L484.9 90.4c13.6-7.2 30.5-7.2 44.6 0.3l370.4 175.2c16.3 8.6 25.8 24.2 26.1 42 0.3 17.8-8.8 33.7-24.3 42.5l-0.6 0.3-371 188.4c-6.9 4-15 6.1-23.1 6.1z m-0.3-432.8c-3.3 0-6.6 0.8-9.6 2.4L125.6 290.6c-6.2 3.3-10.3 10.1-10.5 17.8-0.1 7.7 3.8 14.6 10.5 18.4l370.4 188c7 4 14.9 4 21.2 0.3l371.2-188.5c6.5-3.8 10.3-10.6 10.2-18.2-0.1-7.7-4.2-14.4-11-18.1L517.2 115.1c-3.4-1.8-7-2.7-10.5-2.7zM895 338.6h0.1-0.1z"
      p-id="22160"
    ></path>
    <path
      d="M507.2 902c-10.6 0-21.4-2.7-31-8.2l-371-188.5c-20.8-11.8-32.4-32-32.3-54.6 0.1-22.6 12-42.6 31.7-53.6l92.7-51.7c13.2-7.3 29.8-2.6 37.2 10.6 7.3 13.2 2.6 29.8-10.6 37.2l-92.7 51.7c-3 1.7-3.6 4.3-3.7 6.2 0 1.9 0.6 4.5 3.6 6.2l371.1 188.5c3.4 1.9 6.1 1.9 8.2 0.6l371.5-188.7c1.9-1.1 2.5-3.8 2.4-5.7s-0.7-4.6-3.8-6.2L786.6 597c-13.4-6.9-18.6-23.4-11.7-36.8 6.9-13.4 23.4-18.6 36.8-11.7l93.9 48.8c20.1 10.4 32.9 31 33.3 53.6 0.5 22.6-11.5 43.7-31.1 54.9L536.3 894.5c-8.7 5-18.8 7.5-29.1 7.5z"
      p-id="22161"
    ></path>
    <path
      d="M507.1 722.9c-9.4 0-19-2.4-27.5-7.3L109.5 527.7C91 517.2 80.7 499 81 478.7c0.3-20.3 11.2-38.1 29.1-47.7l85.1-45.2c10-5.3 22.4-1.5 27.7 8.5 5.3 10 1.5 22.4-8.5 27.7l-85.1 45.2c-6.6 3.5-7.4 9.6-7.4 12.1s0.6 8.6 7 12.3L499 679.5c5.2 2.9 10.5 2.9 14.7 0.5l371.9-188.9c5.6-3.2 6.2-9.4 6.2-11.9s-0.8-8.6-7.4-12.1L797.8 421c-10-5.3-13.8-17.7-8.5-27.7 5.3-10 17.7-13.8 27.7-8.5l86.6 46.1c17.9 9.5 28.8 27.4 29.1 47.7 0.3 20.3-10.1 38.5-27.7 48.5l-371.8 189c-7.8 4.5-16.9 6.8-26.1 6.8zM567.9 380c-11.8 0-23.3-4.4-32.2-12.5L460 296.4c-6-5.5-14.1-8.5-22.1-8.1l-138.7 25-2.4-13.4 139.6-25.1c12.7-0.5 24.2 3.6 32.9 11.7l75.8 71.1c9.1 8.4 22 11.1 33.8 7.2l101.3-41.5 5.2 12.6-101.7 41.6c-5.3 1.7-10.5 2.5-15.8 2.5z"
      p-id="22162"
    ></path>
    <path
      d="M287.978335 327.6338a24.7 24.7 0 1 0 13.135096-47.62173 24.7 24.7 0 1 0-13.135096 47.62173Z"
      p-id="22163"
    ></path>
    <path
      d="M451.004657 306.428888a24.7 24.7 0 1 0 8.398153-48.68091 24.7 24.7 0 1 0-8.398153 48.68091Z"
      p-id="22164"
    ></path>
    <path
      d="M557.167475 392.485472a24.7 24.7 0 1 0 8.398154-48.68091 24.7 24.7 0 1 0-8.398154 48.68091Z"
      p-id="22165"
    ></path>
    <path
      d="M701.106839 345.267478a24.7 24.7 0 1 0 8.398153-48.68091 24.7 24.7 0 1 0-8.398153 48.68091Z"
      p-id="22166"
    ></path>
  </svg>
</template>





<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M310.336 653.184c12.864 12.864 12.864 33.6 0 46.528-6.4 6.4-14.72 3.776-23.168 3.776s-16.832-3.264-23.168-9.6L137.6 569.92C137.472 569.792 137.408 569.6 137.344 569.408 134.464 566.592 132.096 559.872 130.496 556.096 129.152 552.704 128.64 555.52 128.384 552 128.384 551.232 128 550.656 128 549.952c0-0.512 0.256-0.896 0.256-1.344 0.192-3.84 0.896-5.76 2.24-9.344 1.344-3.264 3.52-6.016 5.76-8.64C136.768 529.92 137.024 529.088 137.6 528.512l126.336-137.344c12.8-12.864 33.6-6.976 46.4 5.888 12.864 12.8 12.864 33.6 0 46.464L210.048 548.992 310.336 653.184zM960 384 384 384l0 320 576 0L960 384zM320 128 64 128 64 64l256 0 64 0 0 0 0 64 0 128 0 64L320 320 64 320 64 257.024 320 256 320 128M320 832 64 832l0-64 256 0 64 0 0 0 0 64 0 128 0 64L320 1024 64 1024l0-62.976L320 960 320 832"
      p-id="6421"
    ></path>
  </svg>
</template>

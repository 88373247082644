// 静态路由配置
// 书写格式与动态路由格式一致，全部经由框架统一转换
// 比较动态路由在meta中多加入了role角色权限，为数组类型。一个菜单是否有权限显示，取决于它以及后代菜单是否有权限。
// routes 显示在左侧菜单中的路由(显示顺序在动态路由之前)
// 示例如下

// const routes = [
// 	{
// 		name: "demo",
// 		path: "/demo",
// 		meta: {
// 			icon: "el-icon-eleme-filled",
// 			title: "演示",
// 			role: ["SA"]
// 		},
// 		children: [{
// 			name: "demopage",
// 			path: "/demopage",
// 			component: "test/autocode/index",
// 			meta: {
// 				icon: "el-icon-menu",
// 				title: "演示页面",
// 				role: ["SA"]
// 			}
// 		}]
// 	}
// ]

const routes = [
	{
		id: "app-home-page",
		parentId: "root",
		sort: 0,
		name: "app-home-page",
		path: "/home",
		component: "home",
		meta: {
			hidden: false,
			affix: true,
			icon: "ElIconMenu",
			type: "menu",
			title: "首页",
			fullpage: false,
			keepAlive: true,
			menuType: "",
			openType: null,
		},
	},
	{
		id: "jm-base-user-center",
		parentId: "root",
		sort: 0,
		name: "jm-base-user-center",
		path: "/jm/base/userCenter",
		component: "jm/base/userCenter",
		meta: {
			hidden: true,
			icon: "ElIconAvatar",
			type: "menu",
			title: "用户中心",
			fullpage: false,
			keepAlive: true,
			menuType: "",
			openType: null,
		},
	},
];

export default routes;




<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M352 320H128c-17.706667 0-32 14.293333-32 32s14.293333 32 32 32h224c17.706667 0 32-14.293333 32-32s-14.293333-32-32-32zM128 576h128c17.706667 0 32-14.293333 32-32s-14.293333-32-32-32H128c-17.706667 0-32 14.293333-32 32s14.293333 32 32 32zM288 704H128c-17.706667 0-32 14.293333-32 32s14.293333 32 32 32h160c17.706667 0 32-14.293333 32-32s-14.293333-32-32-32zM128 192h736c17.706667 0 32-14.293333 32-32s-14.293333-32-32-32H128c-17.706667 0-32 14.293333-32 32s14.293333 32 32 32zM979.946667 838.933333l-128.426667-102.72C880.426667 694.08 896 644.16 896 592c0-68.373333-26.666667-132.693333-74.986667-181.013333-96.64-96.64-265.28-96.64-362.026666 0C410.666667 459.306667 384 523.626667 384 592s26.666667 132.693333 74.986667 181.013333C507.306667 821.333333 571.626667 848 640 848c62.826667 0 122.346667-22.613333 169.066667-63.786667l130.88 104.746667c5.973333 4.693333 13.013333 7.04 20.053333 7.04 9.386667 0 18.666667-4.16 24.96-12.053333 10.986667-13.866667 8.746667-33.92-5.013333-45.013334zM775.786667 727.786667c-72.426667 72.533333-198.933333 72.533333-271.573334 0-36.266667-36.266667-56.213333-84.48-56.213333-135.786667s19.946667-99.52 56.213333-135.786667S588.693333 400 640 400s99.52 19.946667 135.786667 56.213333c36.16 36.266667 56.213333 84.48 56.213333 135.786667 0 51.306667-19.946667 99.52-56.213333 135.786667z"
      p-id="2683"
    ></path>
  </svg>
</template>




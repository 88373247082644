<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M181.401763 951.171037l-58.053274-0.027645c-39.376582-0.054266-60.186027-20.919001-60.240293-60.377494l0.027645-224.283104c0-10.062745 0-19.08727 0.820132-26.770506h-0.792487l-0.027645-80.667829c-0.054266-91.140128-0.109556-208.832675 0.1372-272.437455 0.219111-53.760123 13.563409-97.184123 40.82538-132.814309 32.567744-42.602844 79.820052-68.690419 144.435407-79.764762 22.641176-3.855952 41.345513-5.660038 58.927672-5.660038l3.55493 0.027645c35.739741 0.464844 48.208617 13.043275 48.208617 48.044795l0.054266 305.934887 92.562305 0.054266c36.314141 0 72.600637-0.054266 108.887132-0.327643 29.286192 0 45.666309 16.324828 45.66631 44.763242v204.676724c0.054266 5.031372 0.163822 21.055178-11.648744 32.978323-10.527589 10.6095-23.981442 11.84021-33.169789 11.84021-33.224055-0.245732-65.654599-0.327643-98.058521-0.327644l-96.281057 0.109556-0.054265 189.499672c-0.054266 32.704945-12.851809 45.447198-45.692931 45.447198l-140.08799 0.081911z m-62.154959-56.166254c1.09351 0.081911 2.46142 0.137201 4.155951 0.137201l57.997984 0.027645 129.778489-0.081911 0.054266-189.33585c0-32.759211 12.633721-45.556754 45.009999-45.61102l107.274513-0.109556c28.930904 0 57.889452 0.054266 86.820356 0.245733l0.054266-182.034524c-33.90801 0.137201-66.228998 0.191467-98.55101 0.191466l-103.418561-0.054266c-32.485834 0-45.146176-12.797543-45.146176-45.666309l-0.054266-308.286752c-13.207097 0.273377-27.727838 1.80511-45.255731 4.78564-50.725327 8.695858-85.48009 27.31726-109.379622 58.600029-19.852113 25.922729-29.150015 57.369319-29.313837 99.015854-0.245732 63.54949-0.191467 181.132481-0.1372 272.190699l0.027645 80.69445v0.355288c-0.027645 5.687683-0.109556 163.959876-0.054266 250.643032 0 1.750844 0.054266 3.171997 0.1372 4.293151z"
      fill="#20ade5"
      p-id="3483"
    ></path>
    <path
      d="M702.376118 951.773081c-15.039852 0-24.418641-6.262083-29.614859-11.512566-12.004032-12.113587-11.867855-28.220327-11.812565-33.525077 0.301022-43.970755 0.163822-289.828147 0.054266-442.085015l-0.054266-100.464652-197.265842-0.1372c-34.399475-0.054266-47.169373-12.633721-47.169374-46.431152l-0.027644-68.744685c-0.027645-45.447198-0.054266-90.648663 0.027644-134.947062 0.054266-31.857168 13.125186-44.872798 45.146176-44.872798l436.944086-0.109556c14.136785 0.027645 31.993345 1.723199 44.982354 14.793096 12.961364 13.043275 14.493097 30.626458 14.411186 44.463244-0.327643 45.09191-0.163822 286.273217-0.081911 458.272641l0.081911 150.888957c0 66.639576-22.723087 119.005167-67.541619 155.674596-37.844849 30.954101-83.948358 48.72875-145.037452 55.865231-7.355592 0.875422-14.82074 1.258355-22.259267 1.640264-5.003728 0.245732-10.036124 0.492489-15.012206 0.957333a60.743021 60.743021 0 0 1-5.770618 0.274401zM472.514886 308.047163l197.239222 0.136176c34.372854 0.054266 47.142752 12.715632 47.197017 46.705553l0.054266 109.73491c0.081911 141.536788 0.219111 363.878606 0 430.162894 1.121154-0.081911 2.242309-0.137201 3.335819-0.191467 6.207817-0.301022 12.41461-0.601021 18.567136-1.312621 49.986082-5.851505 86.874622-19.715936 116.106548-43.615467 32.048635-26.223751 46.97893-61.909226 46.978931-112.304862l-0.081911-150.862335c-0.081911-172.135601-0.245732-413.563665 0.081911-458.683219 0-1.066889 0-1.996576-0.027645-2.816709-0.957333-0.027645-2.105108-0.054266-3.391108-0.054266l-426.087831 0.109556c-0.054266 40.716848-0.027645 82.144272 0 123.763162l0.027645 59.228695z"
      fill="#20ade5"
      p-id="3484"
    ></path>
  </svg>
</template>

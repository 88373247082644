<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M912 176v416h-179.52v-32h147.52v-352h-736v352h175.488v32H112v-416z"
      p-id="63465"
    ></path>
    <path
      d="M436.384 788.512l0.544 2.688a16 16 0 0 0 27.776 5.504l44.032-54.336 56.768 97.664a16 16 0 0 0 21.792 5.856l68.672-39.392 2.368-1.664a16 16 0 0 0 3.52-20.256l-55.904-96.16 68.8-12.064a16 16 0 0 0 6.464-28.8l-256-180.64a16 16 0 0 0-25.12 14.976l36.288 306.624z"
      p-id="63466"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M384 208.002l0 394.034c0 83.134-19.288 143.976-57.324 180.834-34.12 33.06-84.122 49.13-152.862 49.13-24.004 0-51.56-2.992-74.37-7.772l8.18-61.01c14.292 2.538 30.93 4.298 49.548 4.298 43.84 0 75.784-12.918 97.654-39.49C277.46 700.536 288 660.17 288 600.998L288 208.002 384 208.002M416 176.002l-160 0 0 424.996c0 105.16-36.064 134.522-98.824 134.522-29.41 0-55.896-5.042-76.5-12.126L64 847.808c29.4 10.124 74.518 16.192 109.814 16.192C317.91 864 416 796.258 416 602.04L416 176.002 416 176.002z"
      p-id="3912"
    ></path>
    <path
      d="M764.926 192c48.916 0 91.796 6.292 130.2 19.17l-19.162 64.124C848.74 264.284 810.892 253.42 762.992 253.42c-37.214 0-68.924 9.168-91.698 26.512-23.464 17.872-36.388 43.166-36.388 71.226 0 69.948 66.124 96.298 142.694 126.81l0.214 0.086 0.216 0.082c53.954 20.598 91.876 44.522 115.938 73.144C917.186 578.902 928 611.086 928 652.568c0 50.354-18.666 93.42-53.98 124.548C833.32 812.994 771.186 831.96 694.334 831.96c-56.104 0-109.396-12.854-145.23-27.958l15.186-63.206c35.158 14.496 83.764 28.764 138.876 28.764 40.56 0 73.258-8.956 97.182-26.612 25.614-18.9 39.714-47.394 39.714-80.238 0-30.696-12.082-55.844-36.938-76.888-20.314-17.206-49.602-32.096-94.934-48.272-53.72-19.808-94.718-43.894-121.868-71.59C557.844 436.91 544 403.658 544 364.308c0-48.298 19.662-90.202 56.86-121.182C641.02 209.678 697.752 192 764.926 192M764.926 160C610.04 160 512 247.996 512 364.308c0 100.166 75.502 162.88 185.282 203.33 79.4 28.316 110.784 53.616 110.784 95.078 0 45.512-36.278 74.85-104.896 74.85-63.726 0-121.578-21.28-160.788-42.51l0-0.042L512 821.454c37.278 21.276 106.882 42.51 182.334 42.51C875.708 863.96 960 766.86 960 652.568c0-97.1-53.916-159.8-170.556-204.326-86.278-34.382-122.54-53.59-122.54-97.084 0-34.4 31.376-65.738 96.086-65.738 63.692 0 107.488 21.414 133.01 34.582l38.25-128C894.25 174.44 840.376 160 764.926 160L764.926 160z"
      p-id="3913"
    ></path>
  </svg>
</template>

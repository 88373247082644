<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M897.940444 896.76927c6.258541-6.27696 10.256598-14.833847 10.256598-24.530696L908.197042 147.672294c0-9.118682-3.998057-18.235316-10.256598-24.533766l0 0c-6.27696-6.257517-14.815427-9.695826-24.511253-9.695826l0 0-723.784474 0 0 0c-9.68764 0-18.235316 3.437286-24.503067 9.695826l0 0c-6.26775 6.297426-9.686616 15.414061-9.686616 24.533766L115.455033 872.238574c0 9.69685 3.419889 18.253736 9.686616 24.530696 6.26775 6.277984 14.815427 10.276041 24.503067 10.276041l0 0 723.784474 0 0 0C883.126041 907.045311 891.663484 903.047254 897.940444 896.76927L897.940444 896.76927zM149.644717 61.521169l723.784474 0 0 0c23.933085 0 45.586245 9.69685 60.97984 25.110911 15.396665 15.97381 25.073048 37.665855 25.073048 61.039191L959.482079 872.238574c0 23.969924-9.676383 45.64355-25.073048 61.056588l0 0c-15.393595 15.395642-37.046754 25.092491-60.97984 25.092491l0 0-723.784474 0 0 0c-23.364127 0-45.016263-9.69685-60.971653-25.092491l0 0c-15.395642-15.414061-25.082258-37.086663-25.082258-61.056588L63.590805 147.672294c0-23.37436 9.686616-45.065382 25.082258-61.039191l0 0C104.628454 71.218018 126.28059 61.521169 149.644717 61.521169L149.644717 61.521169z"
      p-id="25736"
    ></path>
    <path
      d="M417.41939 698.269357c-6.025227 0-12.047384-2.301416-16.667611-6.89913L259.500731 550.119179c-9.172917-9.148357-9.172917-24.093744 0-33.290197 9.169847-9.147334 24.115234-9.147334 33.288151 0l124.583436 124.606972 312.89429-312.916802c9.194406-9.171893 24.139793-9.171893 33.288151 0 9.196453 9.171893 9.196453 24.116257 0 33.289174L433.991834 691.370227c-4.618181 4.644787-10.642384 6.89913-16.665565 6.89913L417.41939 698.269357z"
      p-id="25737"
    ></path>
  </svg>
</template>



<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M512 106.666667H112a5.333333 5.333333 0 0 0-5.333333 5.333333v800a5.333333 5.333333 0 0 0 5.333333 5.333333h800a5.333333 5.333333 0 0 0 5.333333-5.333333V112a5.333333 5.333333 0 0 0-5.333333-5.333333z m0 74.666666h325.333333a5.333333 5.333333 0 0 1 5.333334 5.333334v160a5.333333 5.333333 0 0 1-5.333334 5.333333H186.666667a5.333333 5.333333 0 0 1-5.333334-5.333333V186.666667a5.333333 5.333333 0 0 1 5.333334-5.333334z m85.333333 250.666667v405.333333a5.333333 5.333333 0 0 1-5.333333 5.333334H432a5.333333 5.333333 0 0 1-5.333333-5.333334V432a5.333333 5.333333 0 0 1 5.333333-5.333333h160a5.333333 5.333333 0 0 1 5.333333 5.333333z m-410.666666-5.333333h160a5.333333 5.333333 0 0 1 5.333333 5.333333v405.333333a5.333333 5.333333 0 0 1-5.333333 5.333334H186.666667a5.333333 5.333333 0 0 1-5.333334-5.333334V432a5.333333 5.333333 0 0 1 5.333334-5.333333z m485.333333 410.666666V432a5.333333 5.333333 0 0 1 5.333333-5.333333h160a5.333333 5.333333 0 0 1 5.333334 5.333333v405.333333a5.333333 5.333333 0 0 1-5.333334 5.333334h-160a5.333333 5.333333 0 0 1-5.333333-5.333334z"
      p-id="97355"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M512 1017.6a498.56 498.56 0 1 1 188.16-960 32.64 32.64 0 0 1 17.92 42.88 33.28 33.28 0 0 1-42.88 17.92 432.64 432.64 0 1 0 269.44 400.64 421.76 421.76 0 0 0-46.08-192 34.56 34.56 0 0 1 14.72-44.8 33.28 33.28 0 0 1 44.16 14.72 490.88 490.88 0 0 1 53.12 223.36A499.2 499.2 0 0 1 512 1017.6z"
      p-id="51933"
    ></path>
    <path
      d="M512 654.72a135.68 135.68 0 1 1 135.68-135.68A135.68 135.68 0 0 1 512 654.72zM512 448a69.76 69.76 0 1 0 69.76 71.04A69.76 69.76 0 0 0 512 448z"
      p-id="51934"
    ></path>
    <path
      d="M609.92 467.84l342.4-394.88-394.88 344.32 52.48 50.56z"
      p-id="51935"
    ></path>
  </svg>
</template>



<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">

      <path
        d="M512 640a42.666667 42.666667 0 0 1 42.666667 42.666667v42.666666a42.666667 42.666667 0 1 1-85.333334 0v-42.666666a42.666667 42.666667 0 0 1 42.666667-42.666667zM512 426.666667a42.666667 42.666667 0 0 1 42.666667 42.666666v85.333334a42.666667 42.666667 0 1 1-85.333334 0v-85.333334a42.666667 42.666667 0 0 1 42.666667-42.666666zM512 256a42.666667 42.666667 0 0 1 42.666667 42.666667v42.666666a42.666667 42.666667 0 1 1-85.333334 0V298.666667a42.666667 42.666667 0 0 1 42.666667-42.666667z"
        p-id="24896"
      ></path>
      <path
        d="M128 213.333333a85.333333 85.333333 0 0 1 85.333333-85.333333h597.333334a85.333333 85.333333 0 0 1 85.333333 85.333333v597.333334a85.333333 85.333333 0 0 1-85.333333 85.333333H213.333333a85.333333 85.333333 0 0 1-85.333333-85.333333V213.333333z m682.666667 0H213.333333v597.333334h597.333334V213.333333z"
        p-id="24897"
      ></path>
    </svg>

</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M840.533333 938.666667H183.466667C128 938.666667 85.333333 896 85.333333 840.533333V405.333333c0-12.8 8.533333-21.333333 21.333334-21.333333s21.333333 8.533333 21.333333 21.333333v435.2C128 872.533333 151.466667 896 183.466667 896h659.2c29.866667 0 55.466667-23.466667 55.466666-55.466667V183.466667C896 151.466667 872.533333 128 840.533333 128H529.066667c-12.8 0-21.333333-8.533333-21.333334-21.333333s8.533333-21.333333 21.333334-21.333334h311.466666C896 85.333333 938.666667 128 938.666667 183.466667v659.2c0 53.333333-42.666667 96-98.133334 96z"
      p-id="4409"
    ></path>
    <path
      d="M426.666667 640c-6.4 0-12.8-2.133333-14.933334-6.4l-192-213.333333c-6.4-6.4-6.4-14.933333-4.266666-23.466667s10.666667-12.8 19.2-12.8h87.466666c-2.133333-42.666667-25.6-153.6-224-258.133333-10.666667-4.266667-14.933333-12.8-12.8-23.466667 2.133333-8.533333 10.666667-14.933333 21.333334-17.066667 153.6-8.533333 270.933333 25.6 349.866666 102.4 74.666667 70.4 91.733333 157.866667 98.133334 196.266667h64c8.533333 0 17.066667 4.266667 19.2 12.8 4.266667 8.533333 2.133333 17.066667-4.266667 23.466667l-192 213.333333c-2.133333 4.266667-8.533333 6.4-14.933333 6.4z m-145.066667-213.333333l145.066667 160 145.066666-160H533.333333c-12.8 0-21.333333-8.533333-21.333333-21.333334 0 0-2.133333-108.8-85.333333-187.733333C369.066667 164.266667 290.133333 134.4 185.6 128c202.666667 134.4 177.066667 275.2 177.066667 281.6-2.133333 10.666667-10.666667 17.066667-21.333334 17.066667h-59.733333z"
      p-id="4410"
    ></path>
  </svg>
</template>




<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M512 39.384615a472.615385 472.615385 0 1 0 472.615385 472.615385A472.615385 472.615385 0 0 0 512 39.384615z m0 866.461539a393.846154 393.846154 0 1 1 393.846154-393.846154 393.846154 393.846154 0 0 1-393.846154 393.846154z m75.854769-373.720616A77.154462 77.154462 0 0 0 590.769231 512a78.454154 78.454154 0 0 0-39.384616-67.859692V196.923077a39.384615 39.384615 0 0 0-78.76923 0v247.217231a78.336 78.336 0 0 0 59.549538 143.714461l70.144 70.144a39.384615 39.384615 0 0 0 55.689846-55.689846zM512 551.384615a39.384615 39.384615 0 1 1 39.384615-39.384615 39.384615 39.384615 0 0 1-39.384615 39.384615z m315.076923-78.76923a39.384615 39.384615 0 1 0 39.384615 39.384615 39.384615 39.384615 0 0 0-39.384615-39.384615zM196.923077 472.615385a39.384615 39.384615 0 1 0 39.384615 39.384615 39.384615 39.384615 0 0 0-39.384615-39.384615z m509.991385 234.299077a39.384615 39.384615 0 1 0 55.689846 0 39.384615 39.384615 0 0 0-55.689846 0z m-389.907693-389.907693a39.384615 39.384615 0 1 0-55.729231 0 39.384615 39.384615 0 0 0 55.808 0.039385zM512 787.692308a39.384615 39.384615 0 1 0 39.384615 39.384615 39.384615 39.384615 0 0 0-39.384615-39.384615z m-250.604308-80.777846a39.384615 39.384615 0 1 0 55.689846 0 39.384615 39.384615 0 0 0-55.689846-0.039385zM706.914462 261.395692a39.384615 39.384615 0 1 0 55.689846 0 39.384615 39.384615 0 0 0-55.689846-0.039384z"
      p-id="45070"
    ></path>
  </svg>
</template>



<template>


  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
      <path
      d="M653.184 713.6c12.864-12.864 33.6-12.864 46.528 0 6.4 6.4 3.776 14.72 3.776 23.232 0 8.384-3.264 16.768-9.6 23.104L569.92 886.4c-0.128 0.128-0.32 0.192-0.512 0.32-2.88 2.88-9.536 5.184-13.312 6.784-3.456 1.344-0.64 1.856-4.096 2.112C551.232 895.616 550.656 896 549.952 896c-0.512 0-0.896-0.256-1.344-0.256-3.84-0.192-5.76-0.896-9.344-2.24-3.264-1.344-6.016-3.52-8.64-5.76-0.64-0.512-1.472-0.768-2.048-1.344l-137.344-126.4c-12.864-12.736-6.976-33.6 5.888-46.4 12.8-12.864 33.6-12.864 46.464 0l105.472 100.352L653.184 713.6zM384 64l0 576 320 0L704 64 384 64zM128 704l0 256L64 960l0-256 0-64 0 0 64 0 128 0 64 0 0 64 0 256L257.024 960 256 704 128 704M832 704l0 256-64 0 0-256 0-64 0 0 64 0 128 0 64 0 0 64 0 256-62.976 0L960 704 832 704"
      p-id="6752"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M901.851653 926.484607a48.255937 48.255937 0 0 1-14.274541 34.442195 48.844737 48.844737 0 0 1-34.457555 14.279661H170.419644a48.220097 48.220097 0 0 1-34.457555-14.279661 48.844737 48.844737 0 0 1-14.279662-34.442195V48.846068a48.317377 48.317377 0 0 1 14.279662-34.467795A48.844737 48.844737 0 0 1 170.419644 0.001331h418.800096a48.742337 48.742337 0 0 1 34.662355 14.131182l263.858857 263.910057a48.844737 48.844737 0 0 1 14.110701 34.667475z"
      fill="#EBECF0"
      p-id="11740"
    ></path>
    <path
      d="M901.851653 926.484607v48.767936a48.317377 48.317377 0 0 1-14.274541 34.467796 48.844737 48.844737 0 0 1-34.457555 14.279661H170.419644a48.844737 48.844737 0 0 1-48.788417-48.844737v-48.721856a48.204737 48.204737 0 0 0 14.279662 34.442195 48.844737 48.844737 0 0 0 34.457555 14.279661h682.648713a48.844737 48.844737 0 0 0 48.773056-48.783296z"
      fill="#C1C7D0"
      p-id="11741"
    ></path>
    <path
      d="M24.167034 536.423034h975.153932v243.849923a48.921536 48.921536 0 0 1-48.721856 48.844736H73.011771a48.342977 48.342977 0 0 1-34.467796-14.274541 48.844737 48.844737 0 0 1-14.376941-34.570195z"
      fill="#0AC905"
      p-id="11742"
    ></path>
    <path
      d="M121.733627 536.412794V438.856441L24.167034 536.412794z m780.118026 0l0.926719-97.556353 97.039234 97.556353z"
      fill="#168E2D"
      p-id="11743"
    ></path>
    <path
      d="M901.851653 312.628125v6.860791h-263.833257a48.844737 48.844737 0 0 1-48.844736-48.844737V0.001331a48.732097 48.732097 0 0 1 34.662355 14.115822l264.268456 263.751337a49.459136 49.459136 0 0 1 13.701102 34.754515z"
      fill="#C1C7D0"
      p-id="11744"
    ></path>
    <path
      d="M265.190721 764.037458H136.346088v-153.94284h126.335836v35.978193H187.382182v21.749732h63.590317v35.973073h-63.590317v24.268769h77.808539zM358.05716 720.532875l-25.098207 43.504583H276.065587l54.384569-80.322456-49.366976-73.620384h56.893366l21.754852 39.321549 22.589411-39.321549h56.888246L386.508963 683.725242l52.705212 80.317336H382.320809zM599.843726 668.662222h-47.682498q-3.353596-24.268768-27.612124-25.103327-27.612124 0.839679-28.446683 45.178821 0 41.845706 30.120921 41.830345 22.584291 0 25.937886-25.932766h48.522177q-8.376309 59.417523-73.625505 61.91096-78.648218-2.508797-81.151894-79.482777 4.177915-76.124061 76.96886-81.151894 69.43735 0.839679 76.96886 62.750638zM752.112328 764.037458h-128.844633v-153.94284h126.335836v35.978193H674.303789v21.749732h63.590317v35.973073H674.303789v24.268769h77.808539zM897.694219 764.037458H777.215655v-153.94284h51.036094v117.964647h69.44247z"
      fill="#FFFFFF"
      p-id="11745"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1006.4 473.6L832 312c-17.6-17.6-43.2-17.6-59.2 0s-17.6 43.2 0 59.2l148.8 140.8-148.8 140.8c-17.6 17.6-17.6 43.2 0 59.2 8 8 20.8 12.8 30.4 12.8 8 0 20.8-4.8 30.4-12.8L1008 550.4c8-8 17.6-25.6 17.6-38.4-1.6-12.8-6.4-30.4-19.2-38.4zM251.2 312c-17.6-17.6-43.2-17.6-59.2 0L17.6 473.6C4.8 481.6 0 499.2 0 512c0 12.8 4.8 30.4 17.6 38.4L192 712c8 8 17.6 12.8 30.4 12.8s20.8-4.8 30.4-12.8c17.6-17.6 17.6-43.2 0-59.2L102.4 512l148.8-140.8c17.6-17.6 17.6-43.2 0-59.2z"
      p-id="3152"
    ></path>
    <path
      d="M340.8 512m-43.2 0a43.2 43.2 0 1 0 86.4 0 43.2 43.2 0 1 0-86.4 0Z"
      p-id="3153"
    ></path>
    <path
      d="M512 512m-43.2 0a43.2 43.2 0 1 0 86.4 0 43.2 43.2 0 1 0-86.4 0Z"
      p-id="3154"
    ></path>
    <path
      d="M683.2 512m-43.2 0a43.2 43.2 0 1 0 86.4 0 43.2 43.2 0 1 0-86.4 0Z"
      p-id="3155"
    ></path>
  </svg>
</template>

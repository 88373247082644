
<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">

    <path
      d="M853.333333 955.733333H170.666667c-66.030933 0-102.4-36.369067-102.4-102.4V170.666667c0-66.030933 36.369067-102.4 102.4-102.4h682.666666c66.030933 0 102.4 36.369067 102.4 102.4v682.666666c0 66.030933-36.369067 102.4-102.4 102.4zM102.4 529.066667v324.266666c0 47.223467 21.0432 68.266667 68.266667 68.266667h682.666666c47.223467 0 68.266667-21.0432 68.266667-68.266667V529.066667H102.4z m0-34.133334h819.2V170.666667c0-47.223467-21.0432-68.266667-68.266667-68.266667H170.666667c-47.223467 0-68.266667 21.0432-68.266667 68.266667v324.266666z"
      p-id="19054"
    ></path>
  </svg>
</template>

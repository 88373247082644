
<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M454.137642 11.17578L54.066443 174.091899c-72.088591 29.490787-72.088591 120.523113 0 150.0139l400.275996 162.916119c36.45389 14.950191 78.846896 14.950191 115.505583 0l400.071199-162.813721c72.190989-29.593186 72.088591-120.830308-0.307196-150.116298L569.745624 11.278178A155.338625 155.338625 0 0 0 454.137642 11.073381z m469.190231 237.871869L532.370147 408.584616l-7.88469 2.457565a55.090429 55.090429 0 0 1-32.562744-2.457565L100.350595 249.047649 491.615517 89.817879a55.090429 55.090429 0 0 1 40.447434 0l391.162524 159.22977z"
      fill="#1890FF"
      p-id="1211"
    ></path>
    <path
      d="M498.681018 729.911317c-20.274916 0-40.652231-3.788747-59.391168-11.673436L53.759247 561.260878C20.479713 547.846666 0 519.891858 0 488.557896c0-31.43636 20.582112-59.391169 53.861646-72.702982l36.556288-15.052589c21.4013-8.806277 47.103341-1.023986 57.240799 17.407756 10.239857 18.226945 1.023986 40.140238-20.479714 48.946515l-36.453889 14.950191c-3.071957 1.228783-4.710334 3.58395-4.710334 6.451109 0 3.071957 1.535978 5.119928 4.710334 6.45111l385.325805 156.874604c14.335799 5.939117 30.924367 5.939117 45.362565 0l385.325806-156.874604c3.071957-1.228783 4.915131-3.58395 4.915131-6.45111 0-3.071957-1.535978-5.222327-4.607936-6.451109L862.195929 463.879842c-21.4013-8.806277-30.412374-30.71957-20.377314-48.946515 10.342255-18.329343 35.839498-26.214033 57.240798-17.407756l44.850572 18.431742c33.177136 13.516611 53.759247 41.266622 53.759248 72.702982 0 31.333961-20.479713 59.391169-53.759248 72.702982L558.481781 718.237881a161.584938 161.584938 0 0 1-59.800763 11.673436z"
      fill="#5DE1C8"
      p-id="1212"
    ></path>
    <path
      d="M498.681018 966.247209c-20.274916 0-40.652231-3.891146-59.391168-11.673437L53.759247 797.59677C20.479713 784.080159 0 756.227749 0 724.996186c0-31.43636 20.582112-59.493567 53.861646-72.805381l36.556288-14.95019c21.4013-8.806277 47.103341-1.023986 57.240799 17.407756 10.239857 18.226945 1.023986 40.140238-20.479714 48.946515l-36.453889 14.95019c-3.071957 1.126384-4.710334 3.58395-4.710334 6.45111 0 3.071957 1.535978 5.119928 4.710334 6.348711l385.325805 156.977002c14.335799 5.939117 30.924367 5.939117 45.362565 0L906.739306 731.1401c3.071957-1.126384 4.915131-3.58395 4.915131-6.348711 0-3.071957-1.535978-5.324725-4.607936-6.45111l-44.850572-18.329343c-21.4013-8.806277-30.412374-30.71957-20.377314-48.946515 10.342255-18.431742 35.839498-26.214033 57.240798-17.407756l44.850572 18.329343c33.177136 13.516611 53.759247 41.369021 53.759248 72.702982 0 31.43636-20.479713 59.493567-53.759248 72.805381L558.481781 954.573772a161.584938 161.584938 0 0 1-59.800763 11.673437z"
      fill="#FF7272"
      p-id="1213"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M819.2 785.066667H204.8a136.533333 136.533333 0 0 1-136.533333-136.533334v-273.066666a136.533333 136.533333 0 0 1 136.533333-136.533334h614.4a136.533333 136.533333 0 0 1 136.533333 136.533334v273.066666a136.533333 136.533333 0 0 1-136.533333 136.533334zM204.8 307.2a68.266667 68.266667 0 0 0-68.266667 68.266667v273.066666a68.266667 68.266667 0 0 0 68.266667 68.266667h614.4a68.266667 68.266667 0 0 0 68.266667-68.266667v-273.066666a68.266667 68.266667 0 0 0-68.266667-68.266667z"
      fill="#4D4D4D"
      p-id="24456"
    ></path>
    <path
      d="M682.666667 546.133333H341.333333a34.133333 34.133333 0 0 1 0-68.266666h341.333334a34.133333 34.133333 0 0 1 0 68.266666z"
      fill="#4D4D4D"
      p-id="24457"
    ></path>
  </svg>
</template>

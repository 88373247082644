import axios from "axios";
import { ElNotification, ElMessageBox } from "element-plus";
import sysConfig from "@/config";
import tool from "@/utils/tool";
import router from "@/router";

axios.defaults.baseURL = sysConfig.API_URL;
axios.defaults.timeout = sysConfig.TIMEOUT;
axios.defaults.withCredentials = true;
axios.defaults.xsrfCookieName='topiam-csrf-cookie'
axios.defaults.xsrfHeaderName='topiam-csrf'

// HTTP request 拦截器
axios.interceptors.request.use(
    (config) => {


        // sa-token
        let tokenName = tool.data.get("tokenName");
        let tokenValue = tool.data.get(tokenName);
        if (tokenName && tokenValue) {
            config.headers[tokenName] = tokenValue;
        }

        if (!sysConfig.REQUEST_CACHE && config.method == "get") {
            config.params = config.params || {};
            config.params["_"] = new Date().getTime();
        }
        Object.assign(config.headers, sysConfig.HEADERS);
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// HTTP response 拦截器
axios.interceptors.response.use(
    (response) => {
        if (response.config.responseType !== "blob") {

            if (response.data.code == "401") {
                // ElNotification.error({
                // 	title: "请求错误",
                // 	message: "用户未登录或访问未授权，请登录后再访问接口！",
                // });

                if (router.currentRoute.name != "login") {
                    router.replace({
                        path: "/login",
                        query: { type: tool.data.get("type") },
                    });
                }
            } else return response;
        } else {
            let blob = new Blob([response.data], {
                type: "application/msexcel;charset=utf-8",
            }); // 将二进制流转为blob
            // console.log(response.headers["content-disposition"])
            // console.log(response.headers["cache-control"])
            let fileName = decodeURI(
                response.headers["content-disposition"]
                    .split(";")[2]
                    .split("=")[1]
            );
            if (fileName.substr(0, 1) == '"') fileName = fileName.substr(1);
            if (fileName.substr(-1) == '"')
                fileName = fileName.substr(0, fileName.length - 1);

            if ("download" in document.createElement("a")) {
                // 非IE下载
                const a = document.createElement("a");
                a.download = fileName;
                a.style.display = "none";
                a.href = URL.createObjectURL(blob);
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(a.href); // 释放URL 对象
                document.body.removeChild(a);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        }
    },
    (error) => {
		// debugger
		// if(opts?.skipErrorHandler) {
		// 	return Promise.resolve(error.response)
		// }
		console.log('error', error)

        if (error.response) {
            if (error.response.status == 404) {
                ElNotification.error({
                    title: "请求错误",
                    message: "Status:404，正在请求不存在的服务器记录！",
                });
            } else if (error.response.status == 500) {
                ElNotification.error({
                    title: "请求错误",
                    message:
                        error.response.data.message ||
                        "Status:500，服务器发生错误！",
                });
            } else if (error.response.status == 401) {
                ElMessageBox.confirm(
                    "当前用户已被登出或无权限访问当前资源，请尝试重新登录后再操作。",
                    "无权限访问",
                    {
                        type: "error",
                        closeOnClickModal: false,
                        center: true,
                        confirmButtonText: "重新登录",
                    }
                )
                    .then(() => {
                        router.replace({
                            path: "/login",
                            query: { type: tool.data.get("type") },
                        });
                    })
                    .catch(() => { });
            } else {
                ElNotification.error({
                    title: "请求错误",
                    message:
                        error.message ||
                        `Status:${error.response.status}，未知错误！`,
                });
            }
        } else {
            ElNotification.error({
                title: "请求错误",
                message: "请求服务器无响应！",
            });
        }

        return Promise.reject(error.response);
    }
);

var http = {
    /** get 请求
     * @param  {接口地址} url
     * @param  {请求参数} params
     * @param  {参数} config
     */
    get: function (url, params = {}, config = {},cb) {
        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                url: url,
                params: params,
                ...config,
            })
                .then((response) => {
                    if(cb){
                        cb(response.data)
                    }
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /** post 请求
     * @param  {接口地址} url
     * @param  {请求参数} data
     * @param  {参数} config
     */
    post: function (url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                url: url,
                data: data,
                ...config,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
	postForm: function (url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios.post(url, data, config)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /** put 请求
     * @param  {接口地址} url
     * @param  {请求参数} data
     * @param  {参数} config
     */
    put: function (url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios({
                method: "put",
                url: url,
                data: data,
                ...config,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /** patch 请求
     * @param  {接口地址} url
     * @param  {请求参数} data
     * @param  {参数} config
     */
    patch: function (url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios({
                method: "patch",
                url: url,
                data: data,
                ...config,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /** delete 请求
     * @param  {接口地址} url
     * @param  {请求参数} data
     * @param  {参数} config
     */
    delete: function (url, data = {}, config = {}) {
        return new Promise((resolve, reject) => {
            axios({
                method: "delete",
                url: url,
                data: data,
                ...config,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    /** jsonp 请求
     * @param  {接口地址} url
     * @param  {JSONP回调函数名称} name
     */
    jsonp: function (url, name = "jsonp") {
        return new Promise((resolve) => {
            var script = document.createElement("script");
            var _id = `jsonp${Math.ceil(Math.random() * 1000000)}`;
            script.id = _id;
            script.type = "text/javascript";
            script.src = url;
            window[name] = (response) => {
                resolve(response);
                document.getElementsByTagName("head")[0].removeChild(script);
                try {
                    delete window[name];
                } catch (e) {
                    window[name] = undefined;
                }
            };
            document.getElementsByTagName("head")[0].appendChild(script);
        });
    },

    /* 统一封装download请求  */
    download: function (url, params, config = {}) {
        return new Promise((resolve, reject) => {
            http({
                method: "get",
                url,
                params,
                responseType: "blob",
                ...config,
            })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default http;

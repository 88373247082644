<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M819.712 101.888H176.64c-51.712 0-93.696 41.984-93.696 93.696v656.384c0 44.032 35.84 79.872 79.872 79.872h51.2c7.68 0 13.824-6.144 13.824-13.824v-36.864c0-7.68-6.144-13.824-13.824-13.824h-37.376c-16.384 0-29.696-13.312-29.696-29.696v-187.904h116.736c8.704 0 15.872-7.168 15.872-15.872v-32.256c0-8.704-7.168-15.872-15.872-15.872H146.944V379.904h745.984c11.264 0 20.48-9.216 20.48-20.48v-163.84c0-51.712-41.984-93.696-93.696-93.696zM146.944 315.904V196.096c0-16.384 13.312-29.696 29.696-29.696l656.896-0.512c8.704 0 15.872 7.168 15.872 15.872v134.144H146.944z"
      p-id="50066"
    ></path>
    <path
      d="M677.888 900.096c2.56 14.336-8.704 28.16-23.04 28.16h-24.064c-11.776 0-20.992-8.192-23.04-19.968l-9.728-60.416c-2.048-11.264-11.776-19.968-23.04-19.968H459.264c-9.216 0-17.408 5.632-20.992 13.824l-32.768 72.704c-3.584 8.192-12.288 13.824-20.992 13.824h-26.624c-17.408 0-28.672-17.92-20.992-33.792l201.728-414.72c3.584-8.192 12.288-12.8 20.992-12.8h37.376l80.896 433.152z m-123.392-307.712l-60.416 130.048c-7.68 15.36 4.096 33.792 20.992 33.792h39.936c14.336 0 25.6-12.8 23.04-27.136l-21.504-135.68-2.048-1.024zM866.816 598.016h-120.32c-20.48 0-36.864-13.824-36.864-30.208 0-16.896 16.896-30.208 36.864-30.208h120.32c20.48 0 36.864 13.824 36.864 30.208 0 16.896-15.872 30.208-36.864 30.208zM866.816 727.552h-120.32c-20.48 0-36.864-13.824-36.864-30.208 0-16.896 16.896-30.208 36.864-30.208h120.32c20.48 0 36.864 13.824 36.864 30.208s-15.872 30.208-36.864 30.208zM866.816 857.088h-120.32c-20.48 0-36.864-13.824-36.864-30.208 0-16.896 16.896-30.208 36.864-30.208h120.32c20.48 0 36.864 13.824 36.864 30.208 0.512 16.896-16.384 30.208-36.864 30.208z"
      p-id="50067"
    ></path>
  </svg>
</template>



// import scTable from "./components/scTable";
// import scTableColumn from "./components/scTable/column.js";
// import scFilterBar from "./components/scFilterBar";
// import scFormTable from "./components/scFormTable";
// import scTableSelect from "./components/scTableSelect";
// import scPageHeader from "./components/scPageHeader";
// import scSelect from "./components/scSelect";
// import scDialog from "./components/scDialog";
// import scForm from "./components/scForm";
// import scTitle from "./components/scTitle";
// import scWaterMark from "./components/scWaterMark";
// import scQrCode from "./components/scQrCode";

// import scStatusIndicator from "./components/scMini/scStatusIndicator";
// import scTrend from "./components/scMini/scTrend";


// import scIconSelect from "@/components/scIconSelect";

//公共对象
import config from "./config";
import api from "./api";
import tool from "./utils/tool";
import http from "./utils/request";
// import { permission, rolePermission } from "./utils/permission";

//指令
// import auth from "./directives/auth";
// import role from "./directives/role";
// import time from "./directives/time";
// import copy from "./directives/copy";

//全局代码错误捕捉
import errorHandler from "./utils/errorHandler";

//系统图标资源
import * as elIcons from "@element-plus/icons-vue";

export default {
	install(app) {
		//注册全局组件
		// app.component("scTable", scTable);
		// app.component("scTableColumn", scTableColumn);
		// app.component("scFilterBar", scFilterBar);
		// app.component("scFormTable", scFormTable);
		// app.component("scTableSelect", scTableSelect);
		// app.component("scPageHeader", scPageHeader);
		// app.component("scSelect", scSelect);
		// app.component("scDialog", scDialog);
		// app.component("scForm", scForm);
		// app.component("scTitle", scTitle);
		// app.component("scWaterMark", scWaterMark);
		// app.component("scQrCode", scQrCode);
		// app.component("scStatusIndicator", scStatusIndicator);
		// app.component("scTrend", scTrend);

		// app.component("scIconSelect", scIconSelect);

		//注册全局指令
		// app.directive("auth", auth);
		// app.directive("role", role);
		// app.directive("time", time);
		// app.directive("copy", copy);

		//统一注册el-icon图标
		for (let icon in elIcons) {
			app.component(`ElIcon${icon}`, elIcons[icon]);
		}
		//统一注册sc-icon图标
		const requireComponent = require.context(
			"@/assets/icons",
			true,
			/\w+\.vue$/
		);
		requireComponent.keys().map((fileName) => {
			let comp = requireComponent(fileName).default;
			var reg = /([^\\/]+)\.([^\\/]+)/i;
			reg.test(fileName);
			comp.name = "ScIcon" + RegExp.$1;
			app.component(comp.name, comp);
		});

		//挂载全局对象
		app.config.globalProperties.$CONFIG = config;
		app.config.globalProperties.$TOOL = tool;
		app.config.globalProperties.$HTTP = http;//可删除
		app.config.globalProperties.$API = api;
		// app.config.globalProperties.$AUTH = permission;//可删除
		// app.config.globalProperties.$ROLE = rolePermission;//可删除


		//关闭async-validator全局控制台警告
		window.ASYNC_VALIDATOR_NO_WARNING = 1;

		//全局代码错误捕捉
		app.config.errorHandler = errorHandler;
	},
};

<template>
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M102.624 65.376a32 32 0 0 0-45.248 45.248l80.32 80.328A36.008 36.008 0 0 0 152 260h54.744l200 200H304c-94.992 0-172 77.008-172 172s77.008 172 172 172h446.744l49.256 49.256v47.432c0 7.128 8.616 10.696 13.656 5.656l19.72-19.72 88 88a31.976 31.976 0 0 0 45.248 0 31.976 31.976 0 0 0 0-45.248l-864-864zM478.744 532l200 200H304c-55.232 0-100-44.768-100-100S248.768 532 304 532h174.744z m71.2-72H724c55.232 0 100-44.768 100-100s-44.768-100-100-100H349.944l-72-72H724c94.992 0 172 77.008 172 172 0 93.656-74.856 169.832-168 171.952v0.048H621.944l-72-72zM800 710.056l104.968 104.976 41.376-41.376a8 8 0 0 0 0-11.312l-132.688-132.688c-5.04-5.04-13.656-1.472-13.656 5.656v74.744z"
            p-id="8405"
        ></path>
    </svg>
</template>




<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M979.478261 706.381913a44.521739 44.521739 0 0 1-11.842783 57.967304l-4.36313 2.849392-393.794783 227.862261-5.38713 4.763826a84.012522 84.012522 0 0 1-43.942957 17.808695l-8.102956 0.400696c-19.055304 0-37.309217-6.544696-52.045913-18.253913l-5.431652-4.719304-393.750261-227.862261a44.521739 44.521739 0 0 1-18.610087-56.186435l2.359652-4.630261a44.521739 44.521739 0 0 1 60.816696-16.250435l405.325913 234.540522 1.335652 1.513739 1.335652-1.513739 405.281391-234.540522a44.521739 44.521739 0 0 1 60.861218 16.250435z m0-222.608696a44.521739 44.521739 0 0 1-11.842783 57.967305l-4.36313 2.849391-393.794783 227.862261-5.38713 4.763826a84.012522 84.012522 0 0 1-43.942957 17.808696l-8.102956 0.400695c-19.055304 0-37.309217-6.544696-52.045913-18.253913l-5.431652-4.719304-393.750261-227.862261a44.521739 44.521739 0 0 1-18.610087-56.186435l2.359652-4.630261a44.521739 44.521739 0 0 1 60.816696-16.250434l405.325913 234.540521 1.335652 1.513739 1.335652-1.513739 405.281391-234.540521a44.521739 44.521739 0 0 1 60.861218 16.250434zM512 0c18.788174 0 36.864 6.099478 51.645217 17.185391l4.58574 3.739826 403.500521 199.68 5.609739 6.144c32.50087 35.439304 32.50087 89.889391 0 125.328696l-5.609739 6.144-403.500521 199.590957-4.541218 3.784347a86.238609 86.238609 0 0 1-43.675826 16.829218L512 578.782609c-18.788174 0-36.864-6.099478-51.645217-17.185392l-4.630261-3.784347L52.268522 358.221913l-5.609739-6.144a92.738783 92.738783 0 0 1 0-125.328696l5.609739-6.144L455.724522 20.925217l4.585739-3.739826c12.688696-9.48313 27.826087-15.315478 43.675826-16.829217z"
      fill="#008df0"
      p-id="5455"
    ></path>
  </svg>
</template>

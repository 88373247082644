
<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M313.359894 448.093505l319.271534 0 0 31.927153-319.271534 0 0-31.927153Z"
      p-id="66103"
    ></path>
    <path
      d="M313.359894 583.783907l223.490074 0 0 31.927153-223.490074 0 0-31.927153Z"
      p-id="66104"
    ></path>
    <path
      d="M313.359894 719.474308l127.708614 0 0 31.927153-127.708614 0 0-31.927153Z"
      p-id="66105"
    ></path>
    <path
      d="M889.411699 554.808992l-39.954991-39.971363-39.957037-39.940664c-7.357572-7.357572-19.284205-7.357572-26.641777 0L453.157847 804.590871c-2.113127 1.730411-3.749394 4.068665-4.591575 6.873548l-36.488029 121.394814c-1.544169 5.160533-0.016373 10.523681 3.539616 14.078647l0.989537 0.763386 0.77055 0.981351c3.554966 3.554966 8.902764 5.082762 14.062274 3.554966l121.394814-36.494169c2.844791-0.857531 5.214768-2.52552 6.938016-4.677533l329.638649-329.630463C896.761084 574.093197 896.761084 562.152237 889.411699 554.808992zM541.135574 889.75553l-95.197152 28.622898 28.622898-95.235015 255.020184-255.020184 66.598814 66.598814L541.135574 889.75553zM856.112292 574.779835l-46.611597 46.611597-66.590628-66.598814 46.605457-46.596248c3.677762-3.679809 9.641591-3.679809 13.319353-0.016373l26.892487 26.892487 26.383904 26.393113C859.791078 565.145407 859.791078 571.116399 856.112292 574.779835z"
      p-id="66106"
    ></path>
    <path
      d="M671.874197 224.898143l0-28.933983c0-22.004153-17.904789-39.908942-39.908942-39.908942L314.026066 156.055219c-22.004153 0-39.908942 17.904789-39.908942 39.908942l0 28.933983L169.687704 224.898143l0 643.563408c0 35.261085 28.591175 63.854307 63.854307 63.854307l127.708614 0 0-47.89073-111.745037 0c-17.631566 0-31.927153-14.297634-31.927153-31.927153L217.578434 272.788873l56.538691 0 0 10.974959c0 22.004153 17.904789 39.908942 39.908942 39.908942l317.938166 0c22.004153 0 39.908942-17.904789 39.908942-39.908942l0-10.974959 56.538691 0 0 164.662247 47.89073 0L776.302595 224.898143 671.874197 224.898143zM623.983467 275.782044 322.007855 275.782044l0-71.836095 301.974589 0L623.982444 275.782044z"
      p-id="66107"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M512 968.96a68.266667 68.266667 0 0 1-20.48-2.944L232.106667 891.733333a74.794667 74.794667 0 0 1-53.76-63.573333L101.546667 136.106667a74.752 74.752 0 0 1 74.282666-82.773334h672.853334a74.24 74.24 0 0 1 55.466666 24.746667 75.861333 75.861333 0 0 1 18.773334 58.069333l-76.8 692.053334a74.794667 74.794667 0 0 1-53.76 63.573333l-259.413334 74.197333a73.856 73.856 0 0 1-20.949333 2.986667zM175.829333 117.333333a10.922667 10.922667 0 0 0-8.106666 3.413334 10.154667 10.154667 0 0 0-2.56 8.106666l76.8 692.053334a9.984 9.984 0 0 0 7.68 8.96l259.413333 74.24a14.122667 14.122667 0 0 0 5.973333 0l259.413334-74.24a10.410667 10.410667 0 0 0 7.68-8.96l76.8-692.053334a10.88 10.88 0 0 0-2.304-7.722666l-0.256-0.384a10.922667 10.922667 0 0 0-8.106667-3.413334z"
      p-id="3752"
    ></path>
    <path
      d="M444.16 712.96l-133.12-38.4a32 32 0 0 1 17.92-61.44l92.16 26.453333V299.093333a32 32 0 0 1 64 0v382.72a31.530667 31.530667 0 0 1-12.8 25.6 31.957333 31.957333 0 0 1-19.2 6.826667 32.469333 32.469333 0 0 1-8.96-1.28zM519.253333 690.346667a31.914667 31.914667 0 0 1 23.466667-38.826667l129.28-32.426667v-72.533333l-97.706667 14.08a31.36 31.36 0 0 1-25.6-7.68 32.64 32.64 0 0 1-11.093333-24.32V317.525333a31.744 31.744 0 0 1 27.306667-31.573333l134.4-19.2a32.085333 32.085333 0 0 1 8.96 63.573333l-107.093334 15.36v146.346667l97.706667-14.08a31.36 31.36 0 0 1 25.6 7.68 32.64 32.64 0 0 1 11.093333 24.32v134.4a32.298667 32.298667 0 0 1-24.32 31.146667l-153.6 38.4a37.546667 37.546667 0 0 1-7.253333 0.853333 32.298667 32.298667 0 0 1-31.146667-24.405333z"
      p-id="3753"
    ></path>
  </svg>
</template>

<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M534.1 207.332c39.1-39.1 90.3-58.6 141.5-58.6 39.2 0 78.4 11.4 112.1 34.3l76.1-76.1c1.6-1.5 3.6-2.3 5.7-2.3s4.1 0.7 5.7 2.3l42.4 42.4c3.1 3.1 3.1 8.2 0 11.4l-76.1 76.1c52.7 77.8 44.6 184.5-24.3 253.5l-101.9 101.9c-1.6 1.5-3.7 2.3-5.7 2.3-2.1 0-4.1-0.7-5.7-2.3l-271.7-271.7c-3.1-3.1-3.1-8.2 0-11.3z m-225.6 225.5c1.6-1.5 3.7-2.3 5.7-2.3 1.156 0 2.282 0.212 3.327 0.67l-0.031 0.034 276.02 276.02c1.065 2.848 0.46 6.2-1.816 8.476l-101.9 101.9c-39.1 39.1-90.3 58.6-141.5 58.6-39.2 0-78.4-11.4-112.1-34.3l-76.1 76.1c-1.6 1.5-3.6 2.3-5.7 2.3s-4.1-0.7-5.7-2.3l-42.4-42.4c-3.1-3.1-3.1-8.2 0-11.3l76.1-76.1c-52.7-77.8-44.6-184.5 24.3-253.5z m273.7-177.4l-59.4 59.4 186.8 186.8 59.4-59.4c24.9-25 38.7-58.1 38.7-93.4 0-35.3-13.8-68.5-38.7-93.4-25-25-58.1-38.7-93.4-38.7-35.3 0-68.5 13.8-93.4 38.7z m-268 268l-59.4 59.4c-25 25-38.7 58.1-38.7 93.4 0 35.3 13.8 68.5 38.7 93.4 25 25 58.1 38.7 93.4 38.7 35.3 0 68.5-13.8 93.4-38.7l59.4-59.4-186.8-186.8z"
      p-id="40603"
    ></path>
    <path
      d="M567.811 548.615a8 8 0 0 1 11.314 0l33.941 33.941a8 8 0 0 1 0 11.314l-66.468 66.468-45.255-45.255 66.468-66.468zM429.22 410.022a8 8 0 0 1 11.313 0l33.941 33.942a8 8 0 0 1 0 11.313l-66.468 66.468-45.255-45.255 66.469-66.468z"
      p-id="40604"
    ></path>
  </svg>
</template>


<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M475.937391 244.869565m18.031305 0l36.285217 0q18.031304 0 18.031304 18.031305l0 217.266087q0 18.031304-18.031304 18.031304l-36.285217 0q-18.031304 0-18.031305-18.031304l0-217.266087q0-18.031304 18.031305-18.031305Z"
      p-id="2852"
    ></path>
    <path
      d="M305.41913 525.801739m18.031305 0l36.285217 0q18.031304 0 18.031305 18.031304l0 217.266087q0 18.031304-18.031305 18.031305l-36.285217 0q-18.031304 0-18.031305-18.031305l0-217.266087q0-18.031304 18.031305-18.031304Z"
      p-id="2853"
    ></path>
    <path
      d="M646.233043 525.801739m18.031305 0l36.285217 0q18.031304 0 18.031305 18.031304l0 217.266087q0 18.031304-18.031305 18.031305l-36.285217 0q-18.031304 0-18.031305-18.031305l0-217.266087q0-18.031304 18.031305-18.031304Z"
      p-id="2854"
    ></path>
    <path
      d="M827.436522 122.212174H196.563478a74.573913 74.573913 0 0 0-74.351304 74.351304v630.873044a74.573913 74.573913 0 0 0 74.351304 74.351304h630.873044a74.573913 74.573913 0 0 0 74.351304-74.351304V196.563478a74.573913 74.573913 0 0 0-74.351304-74.351304z m52.090435 705.224348a52.090435 52.090435 0 0 1-52.090435 52.090435H196.563478a52.090435 52.090435 0 0 1-52.090435-52.090435V196.563478a52.090435 52.090435 0 0 1 52.090435-52.090435h630.873044a52.090435 52.090435 0 0 1 52.090435 52.090435z"
      p-id="2855"
    ></path>
  </svg>
</template>


<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M619.52 490.666667h-0.853333l-85.333334-85.333334h0.853334z"
      p-id="52732"
    ></path>
    <path
      d="M619.52 507.733333h-0.853333a17.066667 17.066667 0 1 1 0-34.133333c9.437867 0 17.476267 7.645867 17.476266 17.066667s-7.185067 17.066667-16.622933 17.066666z m-85.333333-85.333333c-9.437867 0-17.493333-7.645867-17.493334-17.066667s7.202133-17.066667 16.64-17.066666h0.853334a17.066667 17.066667 0 1 1 0 34.133333zM192 866.133333a34.133333 34.133333 0 0 1-24.132267-58.2656l42.666667-42.666666a34.133333 34.133333 0 1 1 48.264533 48.264533l-42.666666 42.666667a33.962667 33.962667 0 0 1-24.132267 10.001066z"
      p-id="52733"
    ></path>
    <path
      d="M619.52 490.666667l-0.426667-0.426667L534.186667 405.333333l212.48-213.333333h85.333333v85.333333z"
      p-id="52734"
    ></path>
    <path
      d="M662.186667 567.466667a33.9968 33.9968 0 0 1-24.132267-10.001067l-170.666667-170.666667a34.133333 34.133333 0 0 1 48.2816-48.2816l170.666667 170.666667A34.133333 34.133333 0 0 1 662.186667 567.466667z"
      p-id="52735"
    ></path>
    <path
      d="M320 806.4h-85.333333a17.066667 17.066667 0 0 1-17.066667-17.066667v-85.333333c0-4.539733 1.792-8.874667 5.000533-12.066133l299.093334-299.093334a16.520533 16.520533 0 0 1 3.566933-2.730666l209.3056-210.141867c3.208533-3.208533 7.560533-5.0176 12.100267-5.0176h85.333333a17.066667 17.066667 0 0 1 17.066667 17.066667v85.333333a17.066667 17.066667 0 0 1-4.9664 12.049067l-212.48 213.333333a17.954133 17.954133 0 0 1-3.618134 2.781867l-295.936 295.918933a17.134933 17.134933 0 0 1-12.066133 4.9664z m-68.266667-34.133333h61.201067l294.0928-294.0928a16.520533 16.520533 0 0 1 3.566933-2.730667L814.933333 270.2848V209.066667h-61.184L546.286933 417.3824a17.954133 17.954133 0 0 1-3.618133 2.781867L251.733333 711.0656v61.201067z"
      p-id="52736"
    ></path>
  </svg>
</template>

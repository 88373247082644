<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M512 0C229.376 0 0 229.376 0 512s229.376 512 512 512 512-229.376 512-512S794.624 0 512 0z m0 969.728c-253.44 0-459.264-205.312-459.264-459.264S258.56 51.712 512 51.712s459.264 205.312 459.264 459.264-205.824 458.752-459.264 458.752z"
      p-id="47284"
    ></path>
    <path
      d="M776.704 487.424L409.088 275.456c-5.632-3.584-12.288-5.12-18.944-5.12-20.48 0-37.376 16.896-37.376 37.376v423.936c0 20.48 16.384 37.376 36.864 37.888h0.512c6.656 0 13.312-1.536 18.944-5.12l367.616-211.968c17.92-10.752 24.064-33.792 13.312-51.712-2.56-5.632-7.68-10.24-13.312-13.312z m-369.152 217.088v-368.64l319.488 184.32-319.488 184.32z"
      p-id="47285"
    ></path>
  </svg>
</template>

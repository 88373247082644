<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M448.8 710.3c-4 0-8.1-1.3-11.4-4.1l-121.7-99.4c-3.8-3.1-6.2-7.6-6.6-12.4-0.4-4.9 1.1-9.7 4.3-13.4l313-365.2c6.4-7.5 17.6-8.5 25.2-2.2L773.3 313c3.8 3.1 6.2 7.6 6.6 12.4 0.4 4.9-1.1 9.7-4.3 13.4L462.6 704c-3.6 4.1-8.7 6.3-13.8 6.3z m-95.9-119.9l93.6 76.5L736 329.3l-93.6-76.5-289.5 337.6z"
      p-id="17349"
    ></path>
    <path
      d="M290.7 779.6c-4.1 0-8.2-1.4-11.5-4.1-5-4.1-7.5-10.5-6.4-16.9l26.1-160.3c1.6-9.9 11-16.6 20.8-15 9.9 1.6 16.6 10.9 15 20.8l-21 129.3L436.2 687c9.3-3.6 19.8 1.2 23.4 10.5 3.6 9.4-1.2 19.8-10.5 23.4l-151.9 57.6c-2.1 0.8-4.3 1.1-6.5 1.1z"
      p-id="17350"
    ></path>
    <path
      d="M511.8 959C265 959 64.2 758.2 64.2 511.4S265 63.9 511.8 63.9s447.6 200.8 447.6 447.6S758.6 959 511.8 959z m0-858.9c-226.8 0-411.3 184.5-411.3 411.3S285 922.7 511.8 922.7s411.3-184.5 411.3-411.3-184.5-411.3-411.3-411.3z"
      p-id="17351"
    ></path>
  </svg>
</template>

import config from "@/config";

//路由
const routes = [
    {
        name: "layout",
        path: "/",
        component: () => import(/* webpackChunkName: "layout" */"@/views/login/navigation"
        ),
        // redirect: config.DASHBOARD_URL || "/dashboard",
        children: [],
    },
    {
        path: "/login",
        component: () =>
            import(/* webpackChunkName: "login" */ "@/views/login/index"),
        meta: {
            title: "登录",
        },
    }
];

export default routes;

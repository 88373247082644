<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M832 1000.32H291.2a112 112 0 0 1-112-112L106.24 164.48A113.28 113.28 0 0 1 218.24 48.64h543.36a37.76 37.76 0 1 1 0 74.88H218.24a37.12 37.12 0 0 0-37.12 37.12L256 884.48a38.4 38.4 0 0 0 37.76 40.96H832a37.12 37.12 0 0 0 37.12-37.12l-60.16-599.04a37.12 37.12 0 0 1 33.28-40.96 36.48 36.48 0 0 1 40.96 33.28l60.8 602.88A113.28 113.28 0 0 1 832 1000.32z"
      p-id="52835"
    ></path>
    <path
      d="M263.04 1002.24a251.52 251.52 0 0 1-256-188.8 67.84 67.84 0 0 1 11.52-59.52 68.48 68.48 0 0 1 53.76-26.24H204.8a37.12 37.12 0 0 1 0 74.24H80c42.24 144 199.04 128 206.08 124.16a37.76 37.76 0 0 1 42.24 32 38.4 38.4 0 0 1-32.64 42.24 256 256 0 0 1-32.64 1.92zM951.04 323.2H392.32a64 64 0 0 1-53.12-26.88 70.4 70.4 0 0 1-10.88-60.8 93.44 93.44 0 0 0-55.68-117.12 37.12 37.12 0 0 1 36.48-64 167.68 167.68 0 0 1 93.44 192h541.44C900.48 113.28 743.04 124.16 736 128a38.4 38.4 0 0 1-40.32-34.56 38.4 38.4 0 0 1 34.56-40.32 270.08 270.08 0 0 1 285.44 183.68 67.2 67.2 0 0 1-64 86.4zM640 570.88H411.52a38.4 38.4 0 0 1-37.76-37.76 37.76 37.76 0 0 1 37.76-37.12H640a37.12 37.12 0 0 1 37.12 37.12 37.76 37.76 0 0 1-37.12 37.76zM640 772.48H411.52a37.76 37.76 0 1 1 0-74.88H640a37.76 37.76 0 0 1 0 74.88z"
      p-id="52836"
    ></path>
  </svg>
</template>

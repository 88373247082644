<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M875.1 301.8L597.3 21.3c-4.5-4.5-9.4-8.3-14.7-11.5-1.4-0.8-2.8-1.6-4.3-2.3-0.9-0.5-1.9-0.9-2.8-1.3-9-4-18.9-6.2-29-6.2h-345c-39.8 0-73 32.2-73 72v880c0 39.8 33.2 72 73 72h623c39.8 0 71-32.2 71-72V352.5c0-19-7-37.2-20.4-50.7zM583.5 110.4L784.3 312H583.5V110.4z m240 841.6h-623V72h311v240c0 39.8 33.2 72 73 72h239v568z"
      p-id="5137"
    ></path>
    <path
      d="M333.2 576h-66.7v192h46v-64h22.1c40.4 0 75.6-20.2 75.6-65.7-0.1-47.3-34.7-62.3-77-62.3z m-0.9 92h-19.8v-56H331c22.2 0 34.5 7 34.5 26.4 0 18.7-10.8 29.6-33.2 29.6zM498 576h-55.5v192h58.1c55.8 0 94.4-30.4 94.4-97s-38.6-95-97-95z m-2.9 155h-5.6V613h5.6c30.9 0 52.7 12.8 52.7 58.3S526 731 495.1 731zM752.5 614v-38h-122v192h46v-73h65v-38h-65v-43z"
      p-id="5138"
    ></path>
  </svg>
</template>

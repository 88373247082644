<template>
    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M647.76 174h117.416c109.792 0 198.824 89.44 198.824 200s-89.032 200-198.824 200H92V492h686.352c64.664 0 117.416-53.136 117.416-118S843.016 256 778.352 256H647.768v69.52c0 7.144-8.64 10.704-13.68 5.648L524.176 220.64a8 8 0 0 1 0-11.28l109.92-110.528c5.032-5.056 13.672-1.496 13.672 5.64v69.528z m-555.76 636h819.296v82H92v-82zM384.24 256H92V174h292.24V256z"
            p-id="10414"
        ></path>
    </svg>
</template>





<template>
  <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M412.081 346.3h443.415l-215.328-212.429c-18.973-18.973-18.973-46.064 0-65.038s44.325-19.884 63.381-0.83l291.385 284.591c18.973 18.973 18.973 44.159 0 63.132l-291.385 284.923c-18.973 18.973-44.325 18.973-63.381-0.083-18.973-18.973-18.973-43.91 0-62.883l215.328-208.534h-443.415c-177.3 0-314.335 138.359-314.335 309.364v44.325c0 25.354-16.074 44.325-41.425 44.325s-41.425-18.973-41.425-44.325v-44.325c0-221.709 169.181-392.213 397.185-392.213z"
      p-id="3522"
    ></path>
  </svg>
</template>
